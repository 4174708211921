import React, { useRef } from 'react';
import { db, auth, storage } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Authentication from '../auth/Authentication';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { dateFnsLocalizer } from 'react-big-calendar';


const AddMedia = (props) => {

   const [title, setTitle] = useState('');
   const [date, setDate] = useState('');
   const [publisher, setPublisher] = useState('');
   const [link, setLink] = useState('');
    const navigate = useNavigate();


   const upload = async () => {
    try {
        const docRef = await addDoc(collection(db, 'media'), {
            publisher: publisher,
            title: title,
            date: date,
            link: link,
        })
        console.log('Document written with id: ', docRef.id);
        setDate('');
        setTitle('');
        setPublisher('');
        setLink('');
        navigate('/Media');
    }catch(error){
        console.error('Error uploading logo: ', error);
    }
}

    return (
        <form className='flex flex-col items-center bg-gray-100 p-6 mt-10 rounded shadow-md'>
           <h2 className='text-2xl font-montserrat font-bold mb-4'>Add Media</h2>
           <div className='w-full mb-4'>
               <label htmlFor="publisher" className='block text-lg font-montserrat mb-2'>Publisher:</label>
               <input
                   className='w-full p-2 border rounded font-montserrat'
                   type="text"
                   id="publisher"
                   value={publisher}
                   onChange={(event) => setPublisher(event.target.value)}
               />
           </div>
           <div className='w-full mb-4'>
               <label htmlFor="title" className='block text-lg font-montserrat mb-2'>Title:</label>
               <input
                   className='w-full p-2 border rounded font-montserrat'
                   type="text"
                   id="title"
                   value={title}
                   onChange={(event) => setTitle(event.target.value)}
               />
           </div>
           <div className='w-full mb-4'>
               <label htmlFor="link" className='block text-lg font-montserrat mb-2'>Link:</label>
               <input
                   className='w-full p-2 border rounded font-montserrat'
                   type="text"
                   id="link"
                   value={link}
                   onChange={(event) => setLink(event.target.value)}
               />
           </div>
           <div className='w-full mb-4'>
               <label htmlFor="date" className='block text-lg font-montserrat mb-2'>Date:</label>
               <input
                   className='w-full p-2 border rounded font-montserrat'
                   type="text"
                   id="date"
                   value={date}
                   onChange={(event) => setDate(event.target.value)}
               />
           </div>
           
           <button onClick={(e) => {
                e.preventDefault();
                upload();
            }}
                type="submit"
                className='bg-gren text-white p-2 rounded font-montserrat hover:bg-org'
            >
                Submit
            </button>
       </form>
    );
}

export default AddMedia;