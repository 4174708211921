import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Link } from 'react-router-dom';
import { PlusIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { storage, db } from '../firebase';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { addDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

export default function Media() {
    const [authenticated, setAuthenticated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [media, setMedia] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [newPhoto, setNewPhoto] = useState(null);
    const [caption, setCaption] = useState('');

    useEffect(() => {
        const fetchPhotos = async () => {
            const querySnapshot = await getDocs(collection(db, 'photo'));
            const photoData = [];
            querySnapshot.forEach((doc) => {
                photoData.push({ id: doc.id, ...doc.data() });
            });
            const formattedPhotos = photoData.map(photo => ({
                original: photo.logoURL,
                thumbnail: photo.logoURL,
                caption: photo.caption,
                id: photo.id,
            }));
            
            setPhotos(formattedPhotos);
        };
        fetchPhotos();
    }, []);

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            setNewPhoto(e.target.files[0]);
        }
    };

    const handleDeleteMedia = (media) => {
        setSelectedMedia(media);
        setShowDeleteModal(true);
    };

    const handleDeletePhoto = (photo) => {
        setSelectedPhoto(photo);
        setShowDeleteModal(true);
    };

    const confirmDeleteMedia = async () => {
        if (selectedMedia) {
            try {
                // Delete from Firestore
                const mediaRef = doc(db, 'media', selectedMedia.id);
                await deleteDoc(mediaRef);
                console.log("Deleted from Firestore: ", selectedMedia.id);

                // Update local state
                setMedia((prevMedia) => prevMedia.filter((media) => media.id !== selectedMedia.id));

                // Hide the modal
                setShowDeleteModal(false);
                setSelectedMedia(null);
            } catch (error) {
                console.error("Error deleting media: ", error);
            }
        }
    };

    const confirmDeletePhoto = async () => {
        if (selectedPhoto) {
            try {
                // Delete from Firestore
                const photoRef = doc(db, 'photo', selectedPhoto.id);
                await deleteDoc(photoRef);
                console.log("Deleted from Firestore: ", selectedPhoto.id);

                // Delete from Storage
                const logoRef = ref(storage, selectedPhoto.original);
                await deleteObject(logoRef);
                console.log("Deleted from Storage: ", selectedPhoto.original);

                // Update local state
                setPhotos((prevPhotos) => prevPhotos.filter((photo) => photo.id !== selectedPhoto.id));

                // Hide the modal
                setShowDeleteModal(false);
                setSelectedPhoto(null);
            } catch (error) {
                console.error("Error deleting photo: ", error);
            }
        }
    };

    const uploadFile = async () => {
        if (newPhoto == null) return;

        try {
            const uniqueName = `${uuidv4()}-${newPhoto.name}`;
            const logoRef = ref(storage, `photo/${uniqueName}`);
            const snapshot = await uploadBytes(logoRef, newPhoto);
            console.log('Upload successful: ', snapshot);

            const downloadURL = await getDownloadURL(snapshot.ref);
            console.log('File available at: ', downloadURL);

            const docRef = await addDoc(collection(db, 'photo'), {
                logoURL: downloadURL,
                caption: caption
            });
            console.log('Document written with id: ', docRef.id);

            // Update local state with the new photo
            setPhotos((prevPhotos) => [...prevPhotos, { original: downloadURL, thumbnail: downloadURL, caption: caption, id: docRef.id }]);
            setCaption('');
            setShowModal(false);
        } catch (error) {
            console.error('Error uploading photo: ', error);
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("Auth state changed: ", user);
            setAuthenticated(!!user);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchMedia = async () => {
            const querySnapshot = await getDocs(collection(db, 'media'));
            const mediaData = [];
            querySnapshot.forEach((doc) => {
                mediaData.push({ id: doc.id, ...doc.data() });
            });
            setMedia(mediaData);
        };
        fetchMedia();
    }, []);

    const handleEditPhoto = (photo) => {
        setSelectedPhoto(photo);
        setCaption(photo.caption)
        setShowEditModal(true);
    };

    const updateCaption = async () => {
        if (selectedPhoto && caption) {
            try {
                const photoRef = doc(db, 'photo', selectedPhoto.id);
                await updateDoc(photoRef, { caption: caption });
                console.log("Caption updated in Firestore: ", selectedPhoto.id);
    
                // Update local state
                setPhotos((prevPhotos) =>
                    prevPhotos.map((photo) =>
                        photo.id === selectedPhoto.id
                            ? { ...photo, caption: caption }
                            : photo
                    )
                );
                // Hide the modal
                setShowEditModal(false);
                setSelectedPhoto(null);
                setCaption(''); // Reset caption state
            } catch (error) {
                console.error("Error updating caption: ", error);
            }
        }
    };
    
    const renderItem = (item) => {
        return (
            <div className="relative">
                <img src={item.original} alt={item.caption} className="w-full h-full object-cover" />
                {authenticated && (
                    <>
                        <button
                            className="absolute top-8 right-2 transform -translate-y-1/2 bg-red-500 text-white rounded p-2 hover:bg-red-700 flex items-center justify-center"
                            onClick={() => handleDeletePhoto({ id: item.id, original: item.original })}
                        >
                            <TrashIcon className="h-6 w-6 mr-1" /> Delete
                        </button>
                        <button
                            className="absolute top-8 left-2 transform -translate-y-1/2 bg-sky-500 text-white rounded p-2 hover:bg-sky-700 flex items-center justify-center"
                            onClick={() => handleEditPhoto({ id: item.id, original: item.original, caption: item.caption })}
                        >
                            <PencilSquareIcon className="h-6 w-6 mr-1" /> Edit
                        </button>
                    </>
                )}
                {item.caption && (
                    <div className="bg-black text-white p-4 w-full text-wrap break-text overflow-wrap">
                        {item.caption}
                    </div>
                )}
            </div>
        );
    };
    
    return (
        <div>
            <div className="inline-flex items-center justify-center w-full relative mt-6">
                <hr className="w-full h-1 my-8 bg-gren border-0 rounded" />
                <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2">
                    <h1 className="text-navy font-montserrat font-bold text-center text-4xl">MEDIA/PRESS</h1>
                </div>
            </div>
            <div className="flex justify-center mt-5">
                {authenticated ? (
                    <>
                        <Link to="/AddMedia">
                            <button className="bg-gren rounded font-montserrat p-2 flex items-center justify-center m-3 hover:text-white">
                                <PlusIcon className="h-6 w-6 mr-2" />
                                Add media
                            </button>
                        </Link>
                    </>
                ) : (
                    <div></div>
                )}
            </div>

            <div className="inline-flex w-full relative mt-6">
                <div className="container m-auto grid grid-cols-3 gap-16 text-start mt-5">
                    {media.map((media, index) => (
                        <div key={index} className="">
                            <div className="border-l-4 border-gren font-montserrat px-3 text-lg">
                                <div className="mb-3">{media.publisher.toUpperCase()}</div>
                                <a href={media.link} className="hover:text-org">
                                     <div className="font-bold mb-3">{media.title}</div>
                                </a>
                                <div className="font-bold">{media.date.toUpperCase()}</div>
                                {authenticated && (
                                    <div>
                                        <Link to={`/editmedia/${media.id}`}>
                                            <button className="bg-sky-500 text-white rounded-full p-1 hover:bg-red-700 mt-5">
                                                <PencilSquareIcon className="h-6 w-6" />
                                            </button>
                                        </Link>
                                        <button
                                            className="bg-red-500 text-white rounded-full p-1 ml-4 hover:bg-red-700 mt-5"
                                            onClick={() => handleDeleteMedia(media)}
                                        >
                                            <TrashIcon className="h-6 w-6" />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="inline-flex items-center justify-center w-full relative mt-6">
                <hr className="w-full h-1 my-8 bg-org border-0 rounded" />
                <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2">
                    <h1 className="text-navy font-montserrat font-bold text-center text-4xl">PHOTO GALLERY</h1>
                </div>
            </div>

            <div className="flex justify-center mt-5">
                {authenticated ? (
                    <button
                        className="bg-gren rounded font-montserrat p-2 flex items-center justify-center m-3 hover:text-white"
                        onClick={() => setShowModal(true)}
                    >
                        <PlusIcon className="h-6 w-6 mr-2" />
                        Add photo
                    </button>
                ) : (
                    <div></div>
                )}
            </div>

            <div className="m-auto w-2/3 mt-8 mb-12">
                <ImageGallery
                    items={photos}
                    lazyLoad={true}
                    showThumbnails={true}
                    showFullscreenButton={true}
                    showPlayButton={false}
                    showNav={true}
                    renderItem={renderItem}
                />
            </div>
            {showEditModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg max-w-md w-full">
                        <div className="border-b p-4 flex justify-between items-center">
                            <h3 className="text-lg font-semibold">Edit Caption</h3>
                        </div>
                        <div className="p-4">
                            <label htmlFor="caption" className='block text-lg font-montserrat mb-2'>Caption:</label>
                            <div className="text-sm text-gray-700">105 character limit</div>
                            <input
                                className='w-full p-2 border rounded font-montserrat'
                                type="text"
                                id="caption"
                                name="caption"
                                value={caption}
                                onChange={(e) => setCaption(e.target.value)}
                            />
                        </div>
                        <div className="border-t p-4 flex justify-end space-x-2">
                            <button
                                type="button"
                                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                                onClick={updateCaption}
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                                onClick={() => setShowEditModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}


            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg max-w-md w-full">
                        <div className="border-b p-4">
                            <h2 className="text-lg font-semibold">Upload Photo</h2>
                        </div>
                        <div className="p-4">
                            <div className='w-full mb-4'>
                                <label htmlFor="file" className='block text-lg font-montserrat mb-2'>Upload Image:</label>
                                <input
                                    type="file"
                                    id="file"
                                    accept=".jpg, .jpeg, .png"
                                    onChange={handleFileChange}
                                />
                            </div>
                            <div className='w-full mb-4'>
                                <label htmlFor="caption" className='block text-lg font-montserrat mb-2'>Caption: </label>
                                <div className="text-sm text-gray-700">105 character limit</div>
                                <input
                                    className='w-full p-2 border rounded font-montserrat'
                                    type="text"
                                    id="caption"
                                    value={caption}
                                    onChange={(e) => setCaption(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end p-4">
                            <button
                                className="bg-grey text-black rounded p-2 mr-2 hover:bg-gray-300"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-gren text-black rounded p-2 hover:bg-green-500"
                                onClick={uploadFile}
                            >
                                Upload
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showDeleteModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg max-w-md w-full">
                        <div className="border-b p-4">
                            <h2 className="text-lg font-semibold">Confirm Delete</h2>
                        </div>
                        <div className="p-4">
                            <p>Are you sure you want to delete this item?</p>
                        </div>
                        <div className="flex justify-end p-4">
                            <button
                                className="bg-gray-500 text-white rounded-full p-2 mr-2 hover:bg-gray-700"
                                onClick={() => {
                                    setShowDeleteModal(false);
                                    setSelectedMedia(null);
                                    setSelectedPhoto(null);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-red-500 text-white rounded-full p-2 hover:bg-red-700"
                                onClick={() => {
                                    if (selectedPhoto) {
                                        confirmDeletePhoto();
                                    } else {
                                        confirmDeleteMedia();
                                    }
                                }}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
