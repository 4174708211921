import React, { useRef, useEffect } from 'react';
import { db, auth, storage } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Authentication from '../auth/Authentication';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';

const EditMedia = (props) => {

   const { id } = useParams();
   const [title, setTitle] = useState('');
   const [date, setDate] = useState('');
   const [publisher, setPublisher] = useState('');

   const navigate = useNavigate();

   useEffect(() => {
       const fetchMedia = async() => {
           try {
            const docRef = doc(db, 'media', id);
            const docSnap = await getDoc(docRef);
            if(docSnap.exists()) {
                const data = docSnap.data();
                setDate(data.date || '');
                setTitle(data.title || '');
                setPublisher(data.publisher || '');
            } else {
                console.error("no document exists");
            }
           } catch (error) {
            console.error("Error fetching document: ", error);
           }
       }
       fetchMedia();
   }, [id]);

   const handleUpdate = async (e) => {
    e.preventDefault();
    try {
        const docRef = doc(db, 'media', id);
        await updateDoc(docRef, { date, title, publisher });
        navigate('/Media');
    } catch (error) {
        console.error("Error updating document: ", error);
    }
   }


   return (
       <form className='flex flex-col items-center bg-gray-100 p-6 mt-10 rounded shadow-md'>
           <h2 className='text-2xl font-montserrat font-bold mb-4'>Edit Media</h2>
           <div className='w-full mb-4'>
               <label htmlFor="publisher" className='block text-lg font-montserrat mb-2'>Publisher:</label>
               <input
                   className='w-full p-2 border rounded font-montserrat'
                   type="text"
                   id="publisher"
                   value={publisher}
                   onChange={(event) => setPublisher(event.target.value)}
               />
           </div>
           <div className='w-full mb-4'>
               <label htmlFor="title" className='block text-lg font-montserrat mb-2'>Title:</label>
               <input
                   className='w-full p-2 border rounded font-montserrat'
                   type="text"
                   id="title"
                   value={title}
                   onChange={(event) => setTitle(event.target.value)}
               />
           </div>
           <div className='w-full mb-4'>
               <label htmlFor="date" className='block text-lg font-montserrat mb-2'>Date:</label>
               <input
                   className='w-full p-2 border rounded font-montserrat'
                   type="text"
                   id="date"
                   value={date}
                   onChange={(event) => setDate(event.target.value)}
               />
           </div>
           
           <button onClick={(e) => {
               e.preventDefault();
           }}
               type="submit"
               className='bg-gren text-white p-2 rounded font-montserrat hover:bg-org'
           >
               Update
           </button>
       </form>
   );
}

export default EditMedia;