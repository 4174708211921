import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../firebase';  
import { ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

const AddSponsors = (props) => {
    
    const [logo, setLogo] = useState(null);
    const [link, setLink] = useState('');
    const fileInputRef = useRef(null);

    const navigate = useNavigate();

    const uploadFile = async () => {
        if(logo == null) return;

        try {
            const uniqueName = `${uuidv4()}-${logo.name}`; 
            const logoRef = ref(storage, `sponsors/${uniqueName}`);
            const snapshot = await uploadBytes(logoRef, logo);
            console.log('Upload successful: ', snapshot);

            const downloadURL = await getDownloadURL(snapshot.ref);
            console.log('File available at: ', downloadURL);

            const docRef = await addDoc(collection(db, 'sponsors'), {
                logoURL: downloadURL,
                link: link
            })
            console.log('Document written with id: ', docRef.id);
            fileInputRef.current.value = ""; 
            setLogo(null);
            setLink('');
            navigate('/Partners');
        }catch(error){
            console.error('Error uploading logo: ', error);
        }
    }

    return (
        <form className='flex flex-col items-center bg-gray-100 p-6 mt-10 rounded shadow-md'>
            <h2 className='text-2xl font-montserrat font-bold mb-4'>Upload Sponsor Logo</h2>
            <div className='w-full mb-4'>
                <label htmlFor="logo" className='block text-lg font-montserrat mb-2'>Sponsor logo:</label>
                <input 
                    className='w-full p-2 border rounded font-montserrat' 
                    type="file" 
                    id="logo" 
                    name="logo"
                    ref={fileInputRef}
                    onChange={(event) => {
                        setLogo(event.target.files[0]);
                    }} 
                />
                <label htmlFor="link" className='block text-lg font-montserrat mb-2'>Sponsor link:</label>
                <input 
                    className='w-full p-2 border rounded font-montserrat'
                    type="url"
                    id="link"
                    value={link}
                    onChange={(event) => setLink(event.target.value)}
                />
            </div>
            <button onClick={(e) => {
                e.preventDefault();
                uploadFile();
            }}
                type="submit" 
                className='bg-gren text-white p-2 rounded font-montserrat hover:bg-org'
            >
                Submit
            </button>
        </form>
    );
}

export default AddSponsors;

/*
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../firebase';  
import { ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';

const AddSponsors = () => {
    
    const [logo, setLogo] = useState(null);
    const [link, setLink] = useState('');
    const fileInputRef = useRef(null);

    const navigate = useNavigate();

    const uploadFile = async () => {
        if(logo == null) return;

        try {
            const logoRef = ref(storage, 'sponsors/${logo.name}');
            const snapshot = await uploadBytes(logoRef, logo);
            console.log('Upload successful: ', snapshot);

            const downloadURL = await getDownloadURL(snapshot.ref);
            console.log('File available at: ', downloadURL);

            const docRef = await addDoc(collection(db, 'sponsors'), {
                logoURL: downloadURL,
                link: link
            })
            console.log('Document written with id: ', docRef.id);
            fileInputRef.current.value = ""; 
            setLogo(null);
            setLink('');
            navigate('/Partners');
        }catch(error){
            console.error('Error uploading logo: ', error);
        }
    }

    return (
        <form className='flex flex-col items-center bg-gray-100 p-6 mt-10 rounded shadow-md'>
            <h2 className='text-2xl font-montserrat font-bold mb-4'>Upload Sponsor Logo</h2>
            <div className='w-full mb-4'>
                <label htmlFor="logo" className='block text-lg font-montserrat mb-2'>Sponsor logo:</label>
                <input 
                    className='w-full p-2 border rounded font-montserrat' 
                    type="file" 
                    id="logo" 
                    name="logo"
                    ref={fileInputRef}
                    onChange={(event) => {
                        setLogo(event.target.files[0]);
                    
                    }} 
                />
                <label htmlFor="link" className='block text-lg font-montserrat mb-2'>Sponsor link:</label>
                <input 
                    className='w-full p-2 border rounded font-montserrat'
                    type="url"
                    id="link"
                    value={link}
                    onChange={(event) => setLink(event.target.value)}
                />
            </div>
            <button onClick={(e) => {
                e.preventDefault();
                uploadFile();
            }}
                type="submit" 
                className='bg-gren text-white p-2 rounded font-montserrat hover:bg-org'
            >
                Submit
            </button>
        </form>
    );
}

export default AddSponsors;



*/

