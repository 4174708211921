import React, { useRef } from 'react';
import { db, auth, storage } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Authentication from '../auth/Authentication';
import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';

const AddPartners = (props) => {

    const [logo, setLogo] = useState(null);
    const [link, setLink] = useState('');
    const [name, setName] = useState('');
    const [social, setSocial] = useState('');
    const [description, setDescription] = useState('');
    const fileInputRef = useRef(null);

    const navigate = useNavigate();

    const uploadFile = async(file) => {
        if(logo == null) return;

        try {
            const uniqueName = `${uuidv4()}-${logo.name}`; 
            const logoRef = ref(storage, `partners/${uniqueName}`);
            const snapshot = await uploadBytes(logoRef, logo);
            console.log('Upload successful: ', snapshot);

            const downloadURL = await getDownloadURL(snapshot.ref);
            console.log('File available at: ', downloadURL);

            const docRef = await addDoc(collection(db, 'partners'), {
                logoURL: downloadURL,
                link: link,
                name: name,
                description: description
            })
            console.log('Document written with id: ', docRef.id);
            fileInputRef.current.value = ""; 
            setLogo(null);
            setLink('');
            setName('');
            setDescription('');
            navigate('/Partners');
        }catch(error){
            console.error('Error uploading logo: ', error);
        }
    }


    return (
        <form className='flex flex-col items-center bg-gray-100 p-6 mt-10 rounded shadow-md'>
            <h2 className='text-2xl font-montserrat font-bold mb-4'>Add Partners</h2>
            <div className='w-full mb-4'>
                <label htmlFor="logo" className='block text-lg font-montserrat mb-2'>Partner logo:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="file"
                    id="logo"
                    name="logo"
                    ref={fileInputRef}
                    onChange={(event) => {
                        setLogo(event.target.files[0]);
                    }}
                />
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="link" className='block text-lg font-montserrat mb-2'>Link:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="url"
                    id="link"
                    value={link}
                    onChange={(event) => setLink(event.target.value)}
                />
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="name" className='block text-lg font-montserrat mb-2'>Organization Acronym:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="text"
                    id="name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="description" className='block text-lg font-montserrat mb-2'>Brief Description:</label>
                <textarea
                    className='w-full p-2 border rounded font-montserrat h-48'
                    type="textarea"
                    id="description"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                />
            </div>
            <button onClick={(e) => {
                e.preventDefault();
                uploadFile();
            }}
                type="submit"
                className='bg-gren text-white p-2 rounded font-montserrat hover:bg-org'
            >
                Submit
            </button>
        </form>
    );
}

export default AddPartners;