import React from "react";

const CustomAgenda = ({ event }) => {
    return (
        <div className="p-2 border-white">
            <h3 className="font-semibold">{event.title}</h3>
            <p><strong>Location:</strong> {event.location}</p>
            <p><strong>Description:</strong> {event.description}</p>
            <p><strong>Affiliated with:</strong> {event.departments.join(", ")}</p>
            <p><strong>Point of contact:</strong> {event.people}</p>
            <p><strong>Type: </strong>{event.type}</p>
            {event.flyerURL && (
                <p>
                    <a href={event.flyerURL} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                        View Flyer
                    </a>
                </p>
            )}
        </div>
    );
};

export default CustomAgenda;
