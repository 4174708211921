import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Ensure firebase is properly configured
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const Contact = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [title, setTitle] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [inbox, setInbox] = useState([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchInbox = async () => {
            const querySnapshot = await getDocs(collection(db, 'inbox'));
            const inboxData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setInbox(inboxData);
        };
        fetchInbox();
    }, []); // Add dependency array to run only once after component mounts

    const upload = async () => {
        try {
            const docRef = await addDoc(collection(db, 'inbox'), {
                firstName: firstName,
                lastName: lastName,
                company: company,
                title: title,
                email: email,
                phone: phone,
                message: message,
            });
            console.log('Document written with id: ', docRef.id);
            setFirstName('');
            setLastName('');
            setCompany('');
            setTitle('');
            setPhone('');
            setEmail('');
            setMessage('');
            setShowModal(true); // Show modal on successful upload
        } catch (error) {
            console.error('Error uploading document: ', error);
        }
    }

    return (
        <form
            className='flex flex-col items-center bg-gray-100 p-6 mt-10 rounded shadow-md'
            onSubmit={(e) => {
                e.preventDefault();
                console.log('Form submitted'); // Log form submission
                upload();
            }}
        >
            <section className="flex bg-grey w-full h-48">
                <p className="m-auto text-navy font-montserrat font-bold text-center text-5xl">
                    CONTACT
                </p>
            </section>
            <section className="text-gray-600 body-font relative">
                <div className="container px-5 p-12 mx-auto">
                    <div className="flex flex-col text-center w-full mb-12">
                        <p className="lg:w-1/2 mx-auto leading-relaxed text-base font-montserrat text-lg">
                            Please fill out the form below so we can put you in contact with the appropriate parties to start making innovation happen!
                        </p>
                    </div>
                    <div className="lg:w-1/2 md:w-2/3 mx-auto">
                        <div className="flex flex-wrap -m-2 bg-grey rounded">
                            <div className="px-6 pt-4 w-1/2">
                                <div className="relative">
                                    <label htmlFor="firstName" className="leading-7 font-montserrat text-medium text-gray-600">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        value={firstName}
                                        className="w-full font-montserrat rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        onChange={(event) => setFirstName(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="px-6 pt-4 w-1/2">
                                <div className="relative">
                                    <label
                                        htmlFor="lastName"
                                        className="leading-7 font-montserrat text-medium text-gray-600"
                                    >
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        value={lastName}
                                        className="w-full font-montserrat rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                        onChange={(event) => setLastName(event.target.value)}

                                    />
                                </div>
                            </div>
                            <div className="px-6 pt-4 w-full">
                                <div className="relative">
                                    <label
                                        htmlFor="company"
                                        className="leading-7 font-montserrat text-medium text-gray-600"
                                    >
                                        Company
                                    </label>
                                    <input
                                        type="text"
                                        id="company"
                                        name="company"
                                        value={company}
                                        onChange={(event) => setCompany(event.target.value)}
                                        className="w-full font-montserrat rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                    />
                                </div>
                            </div>
                            <div className="px-6 pt-4 w-full">
                                <div className="relative">
                                    <label
                                        htmlFor="title"
                                        className="leading-7 font-montserrat text-medium text-gray-600"
                                    >
                                        Job Title
                                    </label>
                                    <input
                                        type="text"
                                        id="title"
                                        name="title"
                                        value={title}
                                        onChange={(event) => setTitle(event.target.value)}
                                        className="w-full font-montserrat rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                    />
                                </div>
                            </div>
                            <div className="px-6 pt-4 w-full">
                                <div className="relative">
                                    <label
                                        htmlFor="email"
                                        className="leading-7  font-montserrat text-medium text-gray-600"
                                    >
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                        className="w-full font-montserrat rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                    />
                                </div>
                            </div>
                            <div className="px-6 pt-4 w-full">
                                <div className="relative">
                                    <label
                                        htmlFor="phone"
                                        className="leading-7 text-medium text-gray-600 font-montserrat"
                                    >
                                        Phone
                                    </label>
                                    <input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={phone}
                                        onChange={(event) => setPhone(event.target.value)}
                                        className="w-full font-montserrat rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                    />
                                </div>
                            </div>
                            <div className="px-6 pt-4 w-full">
                                <div className="relative">
                                    <label
                                        htmlFor="message"
                                        className="leading-7 text-medium text-gray-600 font-montserrat"
                                    >
                                        Message
                                    </label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        value={message}
                                        onChange={(event) => setMessage(event.target.value)}
                                        className="w-full rounded border border-gray-300 font-montserrat focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                                    ></textarea>
                                </div>
                            </div>
                            <div className="p-3 w-full my-3">
                                <button className="flex mx-auto text-white bg-navy font-montserrat border-0 py-2 px-8 focus:outline-none hover:bg-org rounded text-medium" type='submit'>
                                    Submit
                                </button>
                                {showModal && (
                                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                                        <div className="bg-white rounded-lg shadow-lg max-w-md w-full relative">
                                            <button
                                                onClick={() => setShowModal(false)}
                                                className="absolute top-2 right-4 text-2xl font-bold"
                                            >
                                                &times;
                                            </button>
                                            <div className="border-b p-4">
                                                <h2 className="text-lg font-semibold">Successfully Submitted!</h2>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    );
}

export default Contact;
