import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div>
            <section className="bg-navy h-36 mt-6 border-t-8 border-org flex flex-col items-center justify-center">
                <div className="flex flex-wrap justify-center">
                    <section >
                        <Link to="/About" className="rounded-md px-3 py-2 text-sm text-white hover:text-org font-montserrat font-bold">ABOUT</Link>
                    </section>
                    <section>
                        <Link to="/Partners" className="rounded-md px-3 py-2 text-sm text-white hover:text-org font-montserrat font-bold">PARTNERS</Link>
                    </section>
                    <section >
                        <Link to="/Events" className="rounded-md px-3 py-2 text-sm text-white hover:text-org font-montserrat font-bold">EVENTS</Link>
                    </section>
                    <section >
                        <Link to="/Opportunities" className="rounded-md px-3 py-2 text-sm text-white hover:text-org font-montserrat font-bold">OPPORTUNITIES</Link>
                    </section>
                </div>
                <div className="mt-4">
                    <p className='text-zinc-300 text-sm'>NExUS 55 Old Bedford Road, Lincoln, Mass.</p>
                </div>
            </section>
            <section className='bg-gray-700 h-12 text-sm flex items-center justify-center text-zinc-400 m-auto text-center'> Copyright © 2024 NExUS

            </section>
        </div>
    );
}
