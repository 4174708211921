import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';


const AdminRoute = () => {
  const { currentUser, isAdmin } = useAuth();

  //this may be it
  if (!currentUser) {
    return <Navigate to="/Login" />;
  }
  if (!isAdmin) {
    return <Navigate to="/no-access" />;
  }

  return <Outlet />;
};

export default AdminRoute;



