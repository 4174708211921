import React, { useState } from "react"
import { signInWithEmailAndPassword } from "firebase/auth"
import { auth, db } from "../firebase"
import { NavLink, useNavigate } from "react-router-dom"
import Authentication from "./Authentication"
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { addUserProfile } from "../components/profileUtils";
import { doc, setDoc, geDoc } from "firebase/firestore";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    const onLogin = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            await addUserProfile(user.uid, user.email);
            navigate("/Profile", { replace: true });
            window.location.reload(); // Force reload the profile page
            console.log("Login successful: ", user);
        } catch (error) {
            setError(error.message);
            console.log("Login failed: ", error.code, error.message);
        }
};

return (
    <div>
        <section className="flex bg-grey w-full h-56">
            <p className="m-auto text-navy font-montserrat font-bold text-center text-6xl">
                LOGIN FOR DOD ACCESS
            </p>
        </section>
        <hr />
        <div className="flex justify-center py-7">
            <div className="w-96 flex flex-col items-center justify-center">
                <label
                    htmlFor="input-group-1"
                    className="block w-96 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                    Email
                </label>
                <div className="relative mb-4 w-96">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                            className="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 16"
                        >
                            <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                            <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                        </svg>
                    </div>
                    <input
                        type="email"
                        id="input-group-1"
                        value={email}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <label
                    htmlFor="input-group-2"
                    className="w-96 block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                    Password
                </label>
                <div className="w-96 relative mb-6">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <LockClosedIcon className="w-5 h-5 text-zinc-500" />
                    </div>
                    <input
                        type="password"
                        id="input-group-2"
                        value={password}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                {error && <p className="text-red-500">{error}</p>}
                <button className="bg-gray-500 text-white text-center w-full py-2 font-montserrant font-bold rounded mb-2" onClick={onLogin}>LOG IN</button>
                {/* <button className="flex bg-white border border-gray-500 text-gray-500 text-center mt-3 py-1 font-montserrant rounded px-3"> 
                   <img src={microsoft} className="align-center w-6 h-auto mr-2 font-medium"></img>Login using Microsoft</button> */}
            </div>
        </div>
    </div>
);

}

export default Login