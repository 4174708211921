
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db, storage } from '../firebase';  
import { ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import { doc, getDoc, updateDoc, collection, addDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';


const EditSponsors = (props) => {
   
   const { id } = useParams();
   const [logo, setLogo] = useState(null);
   const [link, setLink] = useState('');
   const [existingLogoURL, setExistingLogoURL] = useState('');
   const fileInputRef = useRef(null);

   const navigate = useNavigate();

   useEffect(() => {
       const fetchPartnerData = async() => {
           const docRef = doc(db, 'sponsors', id);
           const docSnap = await getDoc(docRef);
           if(docSnap.exists()) {
               const data = docSnap.data();
               setLink(data.link);
               setExistingLogoURL(data.logoURL);
           } else {
               console.error("No such document");
           }
       }
       fetchPartnerData();
   }, [id]);

   const uploadFile = async () => {
       let logoURL = existingLogoURL;

       if(logo) {
           const uniqueName = `${uuidv4()}-${logo.name}`; 
           const logoRef = ref(storage, `sponsors/${uniqueName}`);
           await uploadBytes(logoRef, logo);
           logoURL = await getDownloadURL(logoRef);

       }

       const updatedData = {
           logoURL,
           link
       }

       const docRef = doc(db, 'sponsors', id);
       await updateDoc(docRef, updatedData);
       console.log('Document written with id: ', id);

       fileInputRef.current.value = ""; 
       setLogo(null);
       navigate('/Partners');
       
   }

   return (
       <form className='flex flex-col items-center bg-gray-100 p-6 mt-10 rounded shadow-md'>
           <h2 className='text-2xl font-montserrat font-bold mb-4'>Upload Sponsor Logo</h2>
           <div className='w-full mb-4'>
               <label htmlFor="logo" className='block text-lg font-montserrat mb-2'>Sponsor logo:</label>
               <input 
                   className='w-full p-2 border rounded font-montserrat' 
                   type="file" 
                   id="logo" 
                   name="logo"
                   ref={fileInputRef}
                   onChange={(event) => {
                       setLogo(event.target.files[0]);
                   }} 
               />
               {existingLogoURL && (
                   <img src={existingLogoURL} alt="sponsor logo" className='mt-4 h-32'/>
               )}
               <label htmlFor="link" className='block text-lg font-montserrat mb-2'>Sponsor link:</label>
               <input 
                   className='w-full p-2 border rounded font-montserrat'
                   type="url"
                   id="link"
                   value={link}
                   onChange={(event) => setLink(event.target.value)}
               />
           </div>
           <button onClick={(e) => {
               e.preventDefault();
               uploadFile();
           }}
               type="submit" 
               className='bg-gren text-white p-2 rounded font-montserrat hover:bg-org'
           >
               Update
           </button>
       </form>
   );
}

export default EditSponsors;