import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import {getAnalytics} from "firebase/analytics";
//for more SDKs --  https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyAhIO9Lfd70wILlgBQaUKJ4cwIuLdRP06k",
  authDomain: "nexus-auth-aeb34.firebaseapp.com",
  databaseURL: "https://nexus-auth-aeb34-default-rtdb.firebaseio.com",
  projectId: "nexus-auth-aeb34",
  storageBucket: "nexus-auth-aeb34.appspot.com",
  messagingSenderId: "509808616476",
  appId: "1:509808616476:web:c26841ddd183a2ddb76bd9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async(user) => {
      setCurrentUser(user);
      if(user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if(userDoc.exists()) {
          setIsAdmin(userDoc.data().isAdmin);
        }
      } else {
        setIsAdmin(false);
      }
      setLoading(false);
    })
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    isAdmin,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )

}

export { auth, db, storage, functions, httpsCallable, analytics };