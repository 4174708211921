import { auth, db, storage } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { PlusIcon, PencilSquareIcon, EyeIcon, EyeSlashIcon, TrashIcon } from "@heroicons/react/24/solid";
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';


export default function Opportunities() {
    const [authenticated, setAuthenticated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedOpportunity, setSelectedOpportunity] = useState(null);
    const [opportunities, setOpportunities] = useState([]);
    const currentDate = new Date();


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("Auth state changed: ", user);
            setAuthenticated(!!user);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchOpportunities = async () => {
            const querySnapshot = await getDocs(collection(db, 'opportunities'));
            const opportunitiesData = [];
            querySnapshot.forEach((doc) => {
                opportunitiesData.push({ id: doc.id, ...doc.data() });
            })
            setOpportunities(opportunitiesData);
        }
        fetchOpportunities();
    }, []);

    const ensureAbsoluteUrl = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            return `https://${url}`;
        }
        return url;
    };



    const togglePrivate = async (opportunity) => {
        try {
            const opportunityRef = doc(db, 'opportunities', opportunity.id);
            await updateDoc(opportunityRef, {
                private: !opportunity.private
            });
            const updatedOpportunities = opportunities.map(op => {
                if (op.id === opportunity.id) {
                    return { ...op, private: !op.private };
                }
                return op;
            });
            setOpportunities(updatedOpportunities);
        } catch (error) {
            console.error('Error updating visibility: ', error);
        }
    };

    const handleDelete = (opportunity) => {
        setSelectedOpportunity(opportunity);
        setShowModal(true);
    };

    const confirmDeleteOpp = async () => {
        if (selectedOpportunity) {
            try {
                console.log("selectedOpportunity: ", selectedOpportunity);

                // Delete from Firestore
                const opportunityRef = doc(db, 'opportunities', selectedOpportunity.id);
                await deleteDoc(opportunityRef);
                console.log("Deleted from Firestore: ", selectedOpportunity.id);

                // Update local state
                setOpportunities((prevOpportunities) => prevOpportunities.filter((opportunity) => opportunity.id !== selectedOpportunity.id));

                // Hide the modal
                setShowModal(false);
                setSelectedOpportunity(null);
            } catch (error) {
                console.error("Error deleting opportunity: ", error);
            }
        }
    };

    const openOpportunities = opportunities.filter(opportunity => {
        const endDateTime = new Date(`${opportunity.endDate}T${opportunity.endTime}`);
        return isNaN(endDateTime) || endDateTime >= currentDate;
    });

    const closedOpportunities = opportunities.filter(opportunity => {
        const endDateTime = new Date(`${opportunity.endDate}T${opportunity.endTime}`);
        return !isNaN(endDateTime) && endDateTime < currentDate;
    });


    return (
        <div>
            <section className="inline-flex items-center justify-start w-full relative mt-6">
                <hr className="w-full h-1 my-8 bg-gren border-0 rounded" />
                <div className="absolute px-4 bg-white">
                    <h1 className="text-navy font-montserrat font-bold text-3xl">Opportunities</h1>
                </div>
            </section>
            <div className="text-center font-montserrat m-auto w-3/5 my-10 font-bold text-xl">NExUS partners offers unique challenge competitions, programs, and opportunities for individuals and organizations to collaborate with defense, academic, and venture communities. These competitions allow for diverse tech talent to receive funding and resources to help the DoD overcome its toughest obstacles.</div>
            <section className="inline-flex items-center justify-start w-full relative mt-6">
                <hr className="w-full h-1 my-8 bg-orange-500 border-0 rounded " />
                <div className="absolute px-4 bg-white  -translate-x-1/2 left-1/2">
                    <h1 className="text-navy font-montserrat font-bold text-3xl">Currently Open</h1>
                </div>
            </section>
            <div className="flex justify-center mt-5">
                {authenticated ? (
                    <Link to='/addopportunities'>
                        <button className="bg-gren rounded font-montserrat p-2 flex items-center justify-center hover:text-white">
                            <PlusIcon className="h-6 w-6 mr-2" />
                            Add Opportunities
                        </button>
                    </Link>
                ) : (
                    <div></div>
                )}
            </div>
            <div className="container m-auto grid grid-cols-3 gap-4 text-center mt-5">
                {openOpportunities.map((opportunity, index) => (
                    (!opportunity.private || authenticated) && (
                        <div key={index} className="bg-orange-200 border border-b-8 border-r-4 rounded">
                            <a href={ensureAbsoluteUrl(opportunity.oppLink)} target="_blank" rel="noopener noreferrer">
                                <div className="grid grid-cols-3 gap-4 m-5">
                                    <div className="col-span-2 mx-5 text-start font-montserrat font-bold text-xl hover:text-org">
                                        <a href={ensureAbsoluteUrl(opportunity.oppLink)} target="_blank" rel="noopener noreferrer">{opportunity.title}</a>
                                    </div>
                                    {opportunity.logoURL !== '' && (
                                    <a href={ensureAbsoluteUrl(opportunity.departmentURL)} target="_blank" rel="noopener noreferrer">
                                        <div className="flex justify-center items-center bg-white rounded hover:border">
                                            <img src={ensureAbsoluteUrl(opportunity.logoURL)} className=" rounded  h-10 w-42 object-fit px-1" />

                                        </div>  </a>
                                    )}

                                    <div className="col-span-3 text-start mx-5 font-montserrat text-lg font-regular">{opportunity.organization}</div>

                                    <div className="col-span-3 text-start mx-5 font-montserrat text-lg font-regular"><b>Topic:</b> {opportunity.topic}</div>
                                    <div className="col-span-3 text-start mx-5 font-montserrat text-lg font-regular"><b>Type:</b> {opportunity.type}</div>
                                    {opportunity.endDate && opportunity.endDate.trim() !== "" && (
                                        <div className="col-span-3 text-start mx-5 font-montserrat text-lg font-regular">
                                            <b>Open until:</b> {opportunity.endDate} at {opportunity.endTime}
                                        </div>
                                    )}                                    {opportunity.flyerURL && (
                                        <a
                                            href={opportunity.flyerURL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="col-span-3 text-start mx-5 font-montserrat text-lg font-regular text-blue-500 hover:underline"
                                        >
                                            View Flyer
                                        </a>
                                    )}

                                </div>
                            </a>
                            <div className="flex justify-start mt-5">
                                {authenticated && (
                                    <>
                                        <Link to={`/editopportunities/${opportunity.id}`}>
                                            <button className="bg-grey rounded font-montserrat p-2 flex items-center justify-center m-3 ml-8 hover:bg-blue-500">
                                                <PencilSquareIcon className="h-6 w-6 mr-2" />
                                                Edit
                                            </button>
                                        </Link>
                                        <button
                                            className="bg-grey rounded font-montserrat p-2 flex items-center justify-center m-3 hover:bg-red-500"
                                            onClick={() => handleDelete(opportunity)}
                                        >
                                            <TrashIcon className="h-6 w-6 mr-2" />
                                            Delete
                                        </button>
                                        <button
                                            className=""
                                            onClick={() => togglePrivate(opportunity)}
                                        >
                                            {opportunity.private ? <EyeSlashIcon className="h-6 w-6 ml-30" /> : <EyeIcon className="h-6 w-6 ml-30" />}
                                        </button>
                                        {showModal && (
                                            <div className="fixed z-10 inset-0 overflow-y-auto">
                                                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                                        <div className="absolute inset-0 bg-grey opacity-10"></div>
                                                    </div>
                                                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                                                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                            <div className="sm:flex sm:items-start">
                                                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                                    <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                                                </div>
                                                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                                                        Delete opportunitity
                                                                    </h3>
                                                                    <div className="mt-2">
                                                                        <p className="text-sm text-gray-500">
                                                                            Are you sure you want to delete this opportunitity? This action cannot be undone.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                                            <button
                                                                type="button"
                                                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                                                                onClick={confirmDeleteOpp}
                                                            >
                                                                Delete
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                                                                onClick={() => setShowModal(false)}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    )
                ))}
            </div>
            <section className="inline-flex items-center justify-start w-full relative mt-6">
                <hr className="w-full h-1 my-8 bg-org border-0 rounded " />
                <div className="absolute px-4 bg-white  -translate-x-1/2 left-1/2">
                    <h1 className="text-navy font-montserrat font-bold text-3xl">Closed</h1>
                </div>
            </section>
            <div className="container m-auto grid grid-cols-3 gap-4 text-center mt-5">
                {closedOpportunities.map((opportunity, index) => (
                    (!opportunity.private || authenticated) && (
                        <div key={index} className="bg-grey rounded border border-b-8 border-r-4 ">
                            <a href={opportunity.oppLink} target="_blank" rel="noopener noreferrer">
                                <div className="grid grid-cols-3 gap-4 m-5">
                                    <div className="col-span-2 mx-5 text-start font-montserrat font-bold text-xl hover:text-org">
                                        <a href={ensureAbsoluteUrl(opportunity.oppLink)} target="_blank" rel="noopener noreferrer">{opportunity.title}</a>
                                    </div>
                                    {opportunity.departmentURL !== '#' && (
                                        <div className="flex justify-center items-center">
                                            <a href={ensureAbsoluteUrl(opportunity.departmentURL)} target="_blank" rel="noopener noreferrer">
                                                <img src={ensureAbsoluteUrl(opportunity.logoURL)} className="rounded hover:border h-10 w-42 object-fit" alt="Department Logo" />
                                            </a>
                                        </div>
                                    )}
                                    <div className="col-span-3 text-start mx-5 font-montserrat text-lg font-regular">{opportunity.organization}</div>
                                    <div className="col-span-3 text-start mx-5 font-montserrat text-lg font-regular"><b>Topic:</b> {opportunity.topic}</div>
                                    <div className="col-span-3 text-start mx-5 font-montserrat text-lg font-regular"><b>Type:</b> {opportunity.type}</div>
                                    {opportunity.endDate && opportunity.endDate.trim() !== "" && (
                                        <div className="col-span-3 text-start mx-5 font-montserrat text-lg font-regular">
                                            <b>Open until:</b> {opportunity.endDate} at {opportunity.endTime}
                                        </div>
                                    )}
                                    {opportunity.flyerURL && (
                                        <a
                                            href={opportunity.flyerURL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="col-span-3 text-start mx-5 font-montserrat text-lg font-regular text-blue-500 hover:underline"
                                        >
                                            View Flyer
                                        </a>
                                    )}

                                </div>
                            </a>
                            <div className="flex justify-start mt-5">
                                {authenticated && (
                                    <>
                                        <Link to={`/editopportunities/${opportunity.id}`}>
                                            <button className="bg-grey rounded font-montserrat p-2 flex items-center justify-center m-3 ml-8 hover:bg-blue-500">
                                                <PencilSquareIcon className="h-6 w-6 mr-2" />
                                                Edit
                                            </button>
                                        </Link>
                                        <button
                                            className="bg-grey rounded font-montserrat p-2 flex items-center justify-center m-3 hover:bg-red-500"
                                            onClick={() => handleDelete(opportunity)}
                                        >
                                            <TrashIcon className="h-6 w-6 mr-2" />
                                            Delete
                                        </button>
                                        <button
                                            className=""
                                            onClick={() => togglePrivate(opportunity)}
                                        >
                                            {opportunity.private ? <EyeSlashIcon className="h-6 w-6 ml-30" /> : <EyeIcon className="h-6 w-6 ml-30" />}
                                        </button>
                                        {showModal && (
                                            <div className="fixed z-10 inset-0 overflow-y-auto">
                                                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                                        <div className="absolute inset-0 bg-grey opacity-10"></div>
                                                    </div>
                                                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                                                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                                            <div className="sm:flex sm:items-start">
                                                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                                    <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                                                </div>
                                                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                                                        Delete Opportunitiy
                                                                    </h3>
                                                                    <div className="mt-2">
                                                                        <p className="text-sm text-gray-500">
                                                                            Are you sure you want to delete this opportunity? This action cannot be undone.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                                            <button
                                                                type="button"
                                                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                                                                onClick={confirmDeleteOpp}
                                                            >
                                                                Delete
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                                                                onClick={() => setShowModal(false)}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    )
                ))}
            </div>

        </div>
    );
}

