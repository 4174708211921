
import React, { useState, useEffect } from 'react';
import '../index.css';
import { Link, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import img from '../pics/nexuslogo.png';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { AiOutlineCaretUp, AiOutlineCaretDown } from 'react-icons/ai'
import 'react-dropdown/style.css';
import { Select, Option } from "@material-tailwind/react";
import Dropdownpls from "./Dropdownpls.jsx"
import Shawn from "../pics/shawn.png"

// // set the dropdown menu element
// const $targetEl = document.getElementById('dropdownMenu');

// // set the element that trigger the dropdown menu on click
// const $triggerEl = document.getElementById('dropdownButton');

// const dropdown = new Dropdown($targetEl, $triggerEl);

// // show the dropdown menu
// dropdown.show();

// // hide the dropdown menu
// dropdown.hide();

// // toggle the dropdown menu
// dropdown.toggle();

// // check if dropdown is visible/open
// dropdown.isVisible();

const Navbar = () => {
   const [authenticated, setAuthenticated] = useState(false);
   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
   const navigate = useNavigate();

   useEffect(() => {
       const unsubscribe = onAuthStateChanged(auth, (user) => {
           console.log("Auth state changed: ", user);
           setAuthenticated(!!user);
       });
       return () => unsubscribe();
   }, []);

   const handleLogout = () => {
       signOut(auth)
           .then(() => {
               console.log("Sign out successful");
               navigate("/Login");
           })
           .catch(error => console.log("Error signing out: ", error));
   };

   const handleLinkClick = () => {
       setIsDropdownOpen(false);
   };

   return (
       <nav className="bg-white">
           <div className="w-full sm:px-6">
               <div className="relative flex h-16 items-center justify-between">
                   <div className="flex items-center justify-left sm:items-stretch sm:justify-start">
                       <div className="flex flex-shrink-0 items-center">
                           <Link to="/">
                               <img src={img} alt="logo" className="h-10 pr-1 mx-auto" />
                           </Link>
                       </div>
                       <div className="hidden sm:ml-6 sm:block">
                           <div className="flex space-x-4">
                               <NavLink to="/About" onClick={handleLinkClick}>ABOUT</NavLink>
                               <NavLink to="/Partners" onClick={handleLinkClick}>PARTNERS</NavLink>
                               <NavLink to="/Opportunities" onClick={handleLinkClick}>OPPORTUNITIES</NavLink>
                               <NavLink to="/Events"onClick={handleLinkClick}>EVENTS</NavLink>
                               <NavLink to="/Media"onClick={handleLinkClick}>MEDIA</NavLink>
                                <NavLink to="/Resources"onClick={handleLinkClick}>RESOURCES</NavLink>
                               <NavLink to="/Contact"onClick={handleLinkClick}>CONTACT</NavLink>
                           </div>
                       </div>
                   </div>
                   <div className="ml-auto hidden sm:block">
                       {authenticated ? (
                           <div className="ml-auto hidden sm:flex items-center space-x-4">
                               <div className="flex items-center">
           
                               <Dropdownpls isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen} />

                                   <button onClick={handleLogout} className="bg-org rounded-md px-3 py-2 text-sm text-white hover:text-black font-montserrat font-bold">LOG OUT</button>
                               </div>
                           </div>
                       ) : (
                           <Link to="/Login">
                               <button className="bg-grey rounded-md px-3 py-2 text-sm text-navy hover:text-org font-montserrat font-bold">LOG IN</button>
                           </Link>
                       )}
                   </div>
               </div>
           </div>
       </nav>
   );
};


function NavLink({ to, children, ...props }) {
   const resolvedPath = useResolvedPath(to);
   const isActive = useMatch({ path: resolvedPath.pathname, end: true });

   return (
       <Link
           to={to}
           className={`no-underline rounded-md px-3 py-2 text-sm font-montserrat font-bold ${isActive ? 'text-org' : 'text-navy hover:text-org'}`}
           {...props}
       >
           {children}
       </Link>
   );
}




export default Navbar