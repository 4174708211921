import React, { useRef, useState, useEffect, forwardRef } from 'react';
import { db, storage } from '../firebase'; // Ensure firebase is properly configured
import { useNavigate, Link } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { PlusIcon } from '@heroicons/react/24/solid';

const AddResources = () => {
    const [title, setTitle] = useState("");
    const [oppLink, setOppLink] = useState("");
    const [type, setType] = useState("");
    const [resources, setResources] = useState([]);


    const navigate = useNavigate();

    const Dropdown = ({ label, value, options, onChange, id }) => (
        <label className='block text-lg font-montserrat mb-2'>
            {label}
            <select value={value} onChange={onChange} className='w-full p-2 border rounded font-montserrat' id={id}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </label>
    );

    useEffect(() => {
        const fetchResources = async () => {
            const querySnapshot = await getDocs(collection(db, 'resources'));
            const resourcesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setResources(resourcesData);
        };

        fetchResources();
    }, []);

    const save = async () => {
        console.log("save function called"); // Log function call

        try {
            const docRef = await addDoc(collection(db, 'resources'), {
                title,
                oppLink,
                type
            });

            console.log('Document written with id: ', docRef.id);
            navigate('/Resources');
            // setTitle("");
            // setOppLink("");
            // setType("");
        } catch (error) {
            console.error('Error: ', error); // Log errors
        }
    };


    return (
        <form
            className='flex flex-col items-center bg-gray-100 p-6 mt-10 rounded shadow-md'
            onSubmit={(e) => {
                e.preventDefault();
                save();
                console.log('Form submitted'); // Log form submission
            }}
        >
            <h2 className='text-2xl font-montserrat font-bold mb-4'>Add Resources</h2>
            <div className='w-full mb-4'>
                <Dropdown
                    label="Section:"
                    options={[
                        { label: 'Select resource type', value: "" },
                        { label: "GETTING STARTED, HOW-TO'S, TUTORIALS", value: "GETTING STARTED, HOW-TO'S, TUTORIALS"},
                        { label: 'SEARCHING NEW AND OLD TOPICS, FINDING POINTS OF CONTACT', value: "SEARCHING NEW AND OLD TOPICS, FINDING POINTS OF CONTACT"},
                        { label: 'Other', value: "Other"},
                    ]}
                    value={type}
                    onChange={(event) => setType(event.target.value)}
                    id='type'
                />
            </div>

            <div className='w-full mb-4'>
                <label htmlFor="title" className='block text-lg font-montserrat mb-2'>Resource Title:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="text"
                    id="title"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                />
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="oppLink" className='block text-lg font-montserrat mb-2'>Resource Link:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="text"
                    id="oppLink"
                    value={oppLink}
                    onChange={(event) => setOppLink(event.target.value)}
                />
            </div>
            <button
                type="submit"
                className='bg-gren text-white p-2 rounded font-montserrat hover:bg-green-700'
            >
                Submit
            </button>
        </form>
    );
}

export default AddResources;