import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Ensure firebase is properly configured
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const EditResources = () => {
    const [formData, setFormData] = useState({
        title: "",
        oppLink: "",
        type: "",
    });
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchResources = async () => {
            try {
                const docRef = doc(db, 'resources', id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setFormData({
                        title: data.title || "",
                        oppLink: data.oppLink || "",
                        type: data.type || "",
                    });
                } else {
                    console.error("No such document");
                }
            } catch (error) {
                console.error("Error getting document:", error);
            }
            setLoading(false);
        };

        fetchResources();
    }, [id]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevData => ({ ...prevData, [id]: value }));
    };

    const handleDropdownChange = (e) => {
        setFormData(prevData => ({ ...prevData, type: e.target.value }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        await handleSubmit();
    };

    const handleSubmit = async () => {
        const docRef = doc(db, 'resources', id);

        const updatedData = {
            title: formData.title,
            oppLink: formData.oppLink,
            type: formData.type,
        };

        try {
            await updateDoc(docRef, updatedData);
            navigate('/Resources');
        } catch (error) {
            console.error("Error updating document:", error);
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <form
            className='flex flex-col items-center bg-gray-100 p-6 mt-10 rounded shadow-md'
            onSubmit={handleFormSubmit}
        >
            <h2 className='text-2xl font-montserrat font-bold mb-4'>Edit Resources</h2>
            <div className='w-full mb-4'>
                <label className='block text-lg font-montserrat mb-2'>
                    Section:
                    <select value={formData.type} onChange={handleDropdownChange} className='w-full p-2 border rounded font-montserrat'>
                        <option value="">Select resource type</option>
                        <option value="GETTING STARTED, HOW-TO'S, TUTORIALS">GETTING STARTED, HOW-TO'S, TUTORIALS</option>
                        <option value="SEARCHING NEW AND OLD TOPICS, FINDING POINTS OF CONTACT">SEARCHING NEW AND OLD TOPICS, FINDING POINTS OF CONTACT</option>
                        <option value="Other">Other</option>
                    </select>
                </label>
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="title" className='block text-lg font-montserrat mb-2'>Resource Title:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="text"
                    id="title"
                    value={formData.title}
                    onChange={handleInputChange}
                />
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="oppLink" className='block text-lg font-montserrat mb-2'>Resource Link:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="text"
                    id="oppLink"
                    value={formData.oppLink}
                    onChange={handleInputChange}
                />
            </div>
            <button
                type="submit"
                className='bg-gren text-white p-2 rounded font-montserrat hover:bg-green-700'
            >
                Update
            </button>
        </form>
    );
}

export default EditResources;
