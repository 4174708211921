import React, { useState } from 'react';
import { auth } from '../firebase';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';

const ChangePasswordModal = ({ isOpen, onClose }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleChangePassword = async () => {
        setError(null);
        setSuccess(null);

        if (newPassword !== confirmPassword) {
            setError('New passwords do not match');
            return;
        }

        try {
            const user = auth.currentUser;
            const credential = EmailAuthProvider.credential(user.email, currentPassword);

            // Re-authenticate the user
            await reauthenticateWithCredential(user, credential);

            // Update the user's password
            await updatePassword(user, newPassword);
            setSuccess('Password changed successfully');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            setError(error.message);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center font-montserrat">
            <div className="bg-white p-8 rounded shadow-md w-1/3">
                <h2 className="text-2xl mb-6 text-center">Change Password</h2>
                <div>
                    <input
                        type="password"
                        value={currentPassword}
                        placeholder="Current Password"
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className="w-full p-2 border rounded-md	 mb-4"
                    />
                </div>
                <div>
                    <input
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="w-full p-2 border rounded-md	 mb-4"
                    />
                </div>
                <div>
                    <input
                        type="password"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="w-full p-2 border rounded-md	 mb-6"
                    />
                </div>
                {error && <p className="text-red-500">{error}</p>}
                {success && <p className="text-green-500">{success}</p>}
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="bg-gray-400 hover:bg-gray-600 text-white px-3 py-1 rounded mr-2"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleChangePassword}
                        className="bg-[#e47c44] hover:bg-[#d65f1f] text-white px-3 py-1 rounded"
                    >
                        Save
                    </button>
                    
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordModal;
