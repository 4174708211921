import React, { useEffect, useState } from 'react';
import { PencilSquareIcon, PlusIcon, TrashIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { collection, getDocs, deleteDoc, doc, getDoc } from 'firebase/firestore';

export default function Resources() {
    const [resources, setResources] = useState([]);
    const [authenticated, setAuthenticated] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedResource, setSelectedResource] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setAuthenticated(true);
                const docRef = doc(db, 'profiles', user.uid);
                const docSnap = await getDoc(docRef);
                if(docSnap.exists() && docSnap.data().isAdmin) {
                    setIsAdmin(true);
                }
            } else {
                setAuthenticated(false);
                setIsAdmin(false);
            }
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchResources = async () => {
            const querySnapshot = await getDocs(collection(db, 'resources'));
            const resource = [];
            querySnapshot.forEach((doc) => {
                resource.push({ id: doc.id, ...doc.data() });
            });
            setResources(resource);
        };
        fetchResources();
    }, []);

    const handleDelete = (resource) => {
        setSelectedResource(resource);
        setShowModal(true);
    };

    const confirmDeleteResource = async () => {
        if (selectedResource) {
            try {
                const resourceRef = doc(db, 'resources', selectedResource.id);
                await deleteDoc(resourceRef);
                setResources((prevResources) => prevResources.filter((resource) => resource.id !== selectedResource.id));
                setShowModal(false);
                setSelectedResource(null);
            } catch (error) {
                console.error("Error deleting resource: ", error);
            }
        }
    };

    const gettingResource = resources.filter(resource => resource.type === "GETTING STARTED, HOW-TO'S, TUTORIALS");
    const searchingResource = resources.filter(resource => resource.type === "SEARCHING NEW AND OLD TOPICS, FINDING POINTS OF CONTACT");
    const otherResource = resources.filter(resource => resource.type === "Other");

    return (
        <div>
            <section className="flex bg-grey w-full h-48">
                <p className="m-auto text-navy font-montserrat font-bold text-center text-5xl">
                    RESOURCES
                </p>
            </section>

            <div className="flex justify-center mt-5">
                {authenticated && isAdmin && (
                    <Link to='/AddResources'>
                        <button className="bg-gren rounded font-montserrat p-2 flex items-center justify-center hover:text-white">
                            <PlusIcon className="h-6 w-6 mr-2" />
                            Add resources
                        </button>
                    </Link>
                )}
            </div>

            <section className='max-w-4xl mx-auto px-4 mb-12'>
                <h1 className='mt-8 text-black font-montserrat font-bold text-left text-3xl mb-6'>
                    GETTING STARTED, HOW-TO'S, TUTORIALS:
                </h1>
                {gettingResource.map((resource) => (
                    <div key={resource.id} className='mt-4 flex items-center'>
                        <h2 className='text-black font-montserrat text-left text-xl flex-1'>
                            {resource.title}: <a href={resource.oppLink} target={'_blank'} rel="noopener noreferrer" className='underline text-org'>{resource.oppLink}</a>
                        </h2>
                        {authenticated && isAdmin && (
                            <div className='flex items-center ml-4'>
                                <Link to={`/editresources/${resource.id}`}>
                                    <button className="bg-grey rounded p-2 flex items-center hover:bg-org">
                                        <PencilSquareIcon className="h-6 w-6 align-middle" />
                                    </button>
                                </Link>
                                <button
                                    className="bg-grey rounded p-2 flex items-center ml-2 hover:bg-red-500"
                                    onClick={() => handleDelete(resource)}
                                >
                                    <TrashIcon className="h-6 w-6 align-middle" />
                                </button>
                            </div>
                        )}
                    </div>
                ))}

            </section>

            <section className='max-w-4xl mx-auto mt-6 mb-12 px-4'>
                <h1 className='mt-8 text-black font-montserrat font-bold text-left text-3xl mb-6'>
                    SEARCHING NEW AND OLD TOPICS, FINDING POINTS OF CONTACT:
                </h1>
                {searchingResource.map((resource) => (
                    <div key={resource.id} className='mt-4 flex items-center'>
                    <h2 className='text-black font-montserrat text-left text-xl flex-1'>
                        {resource.title}: <a href={resource.oppLink} target={'_blank'} rel="noopener noreferrer" className='underline text-org'>{resource.oppLink}</a>
                    </h2>
                    {authenticated && isAdmin && (
                        <div className='flex items-center ml-4'>
                            <Link to={`/editresources/${resource.id}`}>
                                <button className="bg-grey rounded p-2 flex items-center hover:bg-org">
                                    <PencilSquareIcon className="h-6 w-6 align-middle" />
                                </button>
                            </Link>
                            <button
                                className="bg-grey rounded p-2 flex items-center ml-2 hover:bg-red-500"
                                onClick={() => handleDelete(resource)}
                            >
                                <TrashIcon className="h-6 w-6 align-middle" />
                            </button>
                        </div>
                    )}
                </div>
                ))}
            </section>

            <section className='max-w-4xl mx-auto mt-6 mb-10 px-4'>
                <h1 className='mt-8 text-black font-montserrat font-bold text-left text-3xl mb-6'>
                    OTHER:
                </h1>
                {otherResource.length > 0 ? (
                    otherResource.map((resource) => (
                        <div key={resource.id} className='mt-4 flex items-center'>
                        <h2 className='text-black font-montserrat text-left text-xl flex-1'>
                            {resource.title}: <a href={resource.oppLink} target={'_blank'} rel="noopener noreferrer" className='underline text-org'>{resource.oppLink}</a>
                        </h2>
                        {authenticated && isAdmin && (
                            <div className='flex items-center ml-4'>
                                <Link to={`/editresources/${resource.id}`}>
                                    <button className="bg-grey rounded p-2 flex items-center hover:bg-org">
                                        <PencilSquareIcon className="h-6 w-6 align-middle" />
                                    </button>
                                </Link>
                                <button
                                    className="bg-grey rounded p-2 flex items-center ml-2 hover:bg-red-500"
                                    onClick={() => handleDelete(resource)}
                                >
                                    <TrashIcon className="h-6 w-6 align-middle" />
                                </button>
                            </div>
                        )}
                    </div>
                    ))
                ) : (
                    <p className='font-montserrat text-xl mb-6'>No other resources.</p>
                )}
            </section>

            {showModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-grey opacity-10"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                            Delete Resource
                                        </h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Are you sure you want to delete this resource? This action cannot be undone.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={confirmDeleteResource}
                                >
                                    Delete
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
