//for admin stuff
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          setCurrentUser(user);
          if (user) {
            const docRef = doc(db, 'profiles', user.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              setIsAdmin(docSnap.data().isAdmin || false);
            } else {
                setIsAdmin(false);
            }
          } else {
            setCurrentUser(null);
            setIsAdmin(false);
          }
          setLoading(false);
        });
    
        return unsubscribe;
      }, []);

      const value = {
        currentUser,
        isAdmin
      }

      return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
      )

}