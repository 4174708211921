import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../auth/AuthContext';

const EditAbout = () => {
    const [description, setDescription] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const navigate = useNavigate();
    const { currentUser, isAdmin } = useAuth();

    useEffect(() => {
        if (!currentUser || !isAdmin) {
            navigate('/no-access');
            return;
        }
        const fetchAbout = async () => {
            try {
                const docRef = doc(db, 'about', 'B26AptEVkRLEqc9bxWOG');
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setDescription(data.description);
                    setSubtitle(data.subtitle);
                } else {
                    console.error("No document exists");
                }
            } catch (error) {
                console.error("Error getting document:", error);
            }
        };
        fetchAbout();
    }, [currentUser, isAdmin, navigate]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        const docRef = doc(db, 'about', 'B26AptEVkRLEqc9bxWOG');
        await updateDoc(docRef, { description, subtitle });
        navigate('/about'); // Navigate to the about page or wherever appropriate after update
    };

    if (!currentUser || !isAdmin) {
        return null;
    }

    return (
        <div>
            <section className="flex bg-grey w-full h-48">
                <p className="m-auto text-navy font-montserrat font-bold text-center text-5xl">
                    About NExUS
                </p>
            </section>
            <div className='flex justify-end mt-5 mr-5'>
                {currentUser && isAdmin && (
                    <button 
                        onClick={handleUpdate}
                        type = "submit"
                        className='bg-gren rounded font-montserrat p-2 flex items-center justify-center m-3 hover:text-white'
                    >
                        Update
                    </button>
                )}
            </div>
            <form className='flex flex-col items-center p-6 rounded shadow-md'>
                <div className='w-full mb-4'>
                    <input
                        className='w-full p-2 border rounded font-montserrat text-center'
                        type="text"
                        id="subtitle"
                        placeholder='Subtitle'
                        value={subtitle}
                        onChange={(event) => setSubtitle(event.target.value)}
                    />
                </div>
                <div className='w-full mb-4'>
                    <textarea
                        className='w-full p-2 border rounded font-montserrat h-48 text-center'
                        id="description"
                        placeholder='Description'
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                    />
                </div>
            </form>
        </div>
    );
};

export default EditAbout;
