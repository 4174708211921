import React, { useState, useEffect } from 'react';
import { auth, storage, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import './events.css';
import { TrashIcon } from '@heroicons/react/24/solid';
import { deleteDoc, doc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';

export default function DoDResources() {
    const [authenticated, setAuthenticated] = useState(false);
    const [inbox, setInbox] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [readStatus, setReadStatus] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("Auth state changed: ", user);
            setAuthenticated(!!user);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchInbox = async () => {
            const querySnapshot = await getDocs(collection(db, 'inbox'));
            const inboxData = [];
            querySnapshot.forEach((doc) => {
                inboxData.push({ id: doc.id, ...doc.data() });
            });
            setInbox(inboxData);
            console.log(inboxData);
        };
        fetchInbox();
    }, []);

    const toggleReadStatus = (id) => {
        setReadStatus(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleMessageClick = (message) => {
        setSelectedMessage(message);
    };

    const handleDeleteMessage = (message) => {
        setSelectedMessage(message);
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedMessage(null);
    };

    const confirmDeleteMessage = async () => {
        if (selectedMessage) {
            try {
                console.log("selectedMessage: ", selectedMessage);

                // Delete from Firestore
                const messageRef = doc(db, 'inbox', selectedMessage.id);
                await deleteDoc(messageRef);
                console.log("Deleted from Firestore: ", selectedMessage.id);

                // Update local state
                setInbox((prevInbox) => prevInbox.filter((message) => message.id !== selectedMessage.id));

                // Close the delete modal
                closeDeleteModal();
            } catch (error) {
                console.error("Error deleting message: ", error);
            }
        }
    };

    const sortedInbox = [...inbox].sort((a, b) => (readStatus[a.id] ? 1 : -1) - (readStatus[b.id] ? 1 : -1));

    return (
        <div className='h-svh overflow-auto'>
            <div className="inline-flex items-center justify-center w-full relative mt-6 mb-6">
                <hr className="w-full h-1 my-8 bg-org border-0 rounded" />
                <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2">
                    <h1 className="text-navy font-montserrat font-bold text-center text-4xl">Inbox</h1>
                </div>
            </div>
            {sortedInbox.map((item, index) => (
                <div
                    key={index}
                    className={`relative rounded px-8 py-2 mx-8 font-montserrat mb-4 cursor-pointer ${readStatus[item.id] ? 'bg-zinc-100' : 'bg-grey'}`}
                    onClick={() => handleMessageClick(item)}
                >
                    <div className='flex justify-between'>
                        <h1>
                            <b className='text-lg'>{item.firstName}</b> <b className='text-lg'>{item.lastName}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.title} at {item.company}
                        </h1>
                        <div>

                            <input
                                type="checkbox"
                                className='w-6 h-6 mt-4'
                                checked={!!readStatus[item.id]}
                                onChange={() => toggleReadStatus(item.id)}
                                onClick={(e) => e.stopPropagation()}
                            />
                            <button
                                className="bg-red-500 text-white rounded p-1 text-2xl ml-5 font-bold hover:bg-red-700"
                                onClick={() => handleDeleteMessage(item)}
                            >
                                <TrashIcon className="h-6 w-6" />
                            </button>
                        </div>
                    </div>
                    <h1>{item.email}</h1>
                    <h1 className='mt-3'>{item.message}</h1>
                </div>
            ))}
            {sortedInbox.length === 0 && (
                                        <p className='font-montserrat text-center'>No messages currently in inbox.</p>
                                    )}
            {showDeleteModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                            Delete Message
                                        </h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Are you sure you want to delete this message? This action cannot be undone.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={confirmDeleteMessage}
                                >
                                    Delete
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                                    onClick={closeDeleteModal}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {selectedMessage && !showDeleteModal && (
                <Modal message={selectedMessage} onClose={() => setSelectedMessage(null)} />
            )}
        </div>
    );
}

const Modal = ({ message, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg p-8 m-4 max-w-lg w-full relative">
                <p><b>Name:</b> {message.firstName} {message.lastName} </p>
                <p><b>Job Title:</b> {message.title}</p>
                <p><b>Company:</b> {message.company}</p>
                <p><b>Email:</b> {message.email}</p>
                <p><b>Phone:</b> {message.phone}</p>
                <p className="mt-4"><b>Message:</b> {message.message}</p>
                <button onClick={onClose} className="absolute top-2 right-4 text-2xl font-bold">&times;</button>
            </div>
        </div>
    );
};
