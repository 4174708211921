import React, { useState, useEffect } from 'react';
import Draper from "../Partners/image 14.png";
import MTC from "../Partners/image 15.png";
import UMLARC from "../Partners/image 19.png";
import { auth, storage, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { ref, deleteObject } from 'firebase/storage';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';

export default function Partners() {
   const [authenticated, setAuthenticated] = useState(false);
   const navigate = useNavigate();

   const [showSponsorModal, setShowSponsorModal] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const [selectedSponsor, setSelectedSponsor] = useState(null);
   const [selectedPartner, setSelectedPartner] = useState(null);

   const handleDeleteSponsor = (sponsor) => {
       setSelectedSponsor(sponsor);
       setShowSponsorModal(true);
   };

   const handleDeletePartner = (partner) => {
       setSelectedPartner(partner);
       setShowModal(true);
   };

   const confirmDeleteSponsor = async () => {
       if (selectedSponsor) {
           try {
               console.log("selectedSponsor: ", selectedSponsor);

               // Delete from Firestore
               const sponsorRef = doc(db, 'sponsors', selectedSponsor.id);
               await deleteDoc(sponsorRef);
               console.log("Deleted from Firestore: ", selectedSponsor.id);

               // Delete from Storage
               const logoRef = ref(storage, selectedSponsor.logoURL);
               await deleteObject(logoRef);
               console.log("Deleted from Storage: ", selectedSponsor.logoURL);

               // Update local state
               setSponsors((prevSponsors) => prevSponsors.filter((sponsor) => sponsor.id !== selectedSponsor.id));

               // Hide the modal
               setShowSponsorModal(false);
               setSelectedSponsor(null);
           } catch (error) {
               console.error("Error deleting sponsor: ", error);
           }
       }
   };

   const confirmDeletePartner = async () => {
       if (selectedPartner) {
           try {
               console.log("selectedPartner: ", selectedPartner);

               // Delete from Firestore
               const partnerRef = doc(db, 'partners', selectedPartner.id);
               await deleteDoc(partnerRef);
               console.log("Deleted from Firestore: ", selectedPartner.id);

               // Delete from Storage
               const logoRef = ref(storage, selectedPartner.logoURL);
               await deleteObject(logoRef);
               console.log("Deleted from Storage: ", selectedPartner.logoURL);

               // Update local state
               setPartners((prevPartners) => prevPartners.filter((partner) => partner.id !== selectedPartner.id));

               // Hide the modal
               setShowModal(false);
               setSelectedPartner(null);
           } catch (error) {
               console.error("Error deleting partner: ", error);
           }
       }
   };

   const [sponsors, setSponsors] = useState([]);
   const [partners, setPartners] = useState([]);

   useEffect(() => {
       const unsubscribe = onAuthStateChanged(auth, (user) => {
           console.log("Auth state changed: ", user);
           setAuthenticated(!!user);
       });
       return () => unsubscribe();
   }, []);

   // Fetch sponsors
   useEffect(() => {
       const fetchSponsors = async () => {
           const querySnapshot = await getDocs(collection(db, 'sponsors'));
           const sponsorsData = [];
           querySnapshot.forEach((doc) => {
               sponsorsData.push({ id: doc.id, ...doc.data() });
           });
           setSponsors(sponsorsData);
       };
       fetchSponsors();
   }, []);

   // Fetch partners
   useEffect(() => {
       const fetchPartners = async () => {
           const querySnapshot = await getDocs(collection(db, 'partners'));
           const partnersData = [];
           querySnapshot.forEach((doc) => {
               partnersData.push({ id: doc.id, ...doc.data() });
           });
           setPartners(partnersData);
       };
       fetchPartners();
   }, []);

   const ensureAbsoluteUrl = (url) => {
       if (!/^https?:\/\//i.test(url)) {
           return `https://${url}`;
       }
       return url;
   };

   return (
       <div>
           <section className="flex bg-grey w-full h-48">
               <p className="m-auto text-navy font-montserrat font-bold text-center text-5xl">
                   PARTNERS AND SPONSORS
               </p>
           </section>
           <div className="inline-flex items-center justify-center w-full relative mt-6">
               <hr className="w-full h-1 my-8 bg-gren border-0 rounded" />
               <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2">
                   <h1 className="text-navy font-montserrat font-bold text-center text-4xl">SPONSORS</h1>
               </div>
           </div>

           <div className="container m-auto grid grid-cols-3 gap-4 text-center mt-5">
               {sponsors.map((sponsor, index) => (
                   <div key={index} className="flex items-center justify-center">
                       <a href={ensureAbsoluteUrl(sponsor.link)} target='_blank' rel="noopener noreferrer">
                           <img src={sponsor.logoURL} alt={`Sponsor logo ${index}`} className="h-100 w-full object-contain t-6 mt-2" />
                       </a>
                       {authenticated && (<>
                           <Link to={`/editsponsors/${sponsor.id}`}>
                           <button
                               className="bg-sky-500 text-white rounded-full p-1 ml-4 hover:bg-red-700"
                           >
                               <PencilSquareIcon className="h-6 w-6" />
                           </button>
                           </Link>
                           <button
                               className="bg-red-500 text-white rounded-full p-1 ml-4 hover:bg-red-700"
                               onClick={() => handleDeleteSponsor(sponsor)}
                           >
                               <TrashIcon className="h-6 w-6" />
                           </button>
                       </>
                       )}
                   </div>
               ))}
           </div>

           <div className="flex justify-center mt-10">
               {authenticated ? (
                   <>
                       <Link to="/addsponsors">
                           <button className="bg-gren rounded font-montserrat p-2 flex items-center justify-center m-3">
                               <PlusIcon className="h-6 w-6 mr-2" />
                               Add Sponsors
                           </button>
                       </Link>
                   </>
               ) : (
                   <div></div>
               )}
           </div>

           <div className="inline-flex items-center justify-center w-full relative mt-6">
               <hr className="w-full h-1 my-8 bg-org border-0 rounded" />
               <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2">
                   <h1 className="text-navy font-montserrat font-bold text-center text-4xl">PARTNERS</h1>
               </div>
           </div>

           <div className="container m-auto grid grid-cols-3 gap-4 text-center mt-5">
               {partners
               .filter((partner) => partner.id !== 'wLYUJG8QE1jBKSexJekU')
               .map((partner, index) => (
                   <div key={index} className="border border-navy p-4">
                       <a href={ensureAbsoluteUrl(partner.link)} target='_blank' rel="noopener noreferrer">
                           <img src={partner.logoURL} alt={`Partner logo ${index}`} className="h-28 w-full object-contain mb-5" />
                       </a>
                       <p className="font-montserrat mb-4">{partner.description}</p>
                       
                       {authenticated && (<>
                           <Link to={`/editpartners/${partner.id}`}>
                               <button
                                   className="bg-sky-500 text-white rounded-full p-1 ml-4 hover:bg-red-700"
                               >
                                   <PencilSquareIcon className="h-6 w-6" />
                               </button>
                           </Link>
                           <button
                               className="bg-red-500 text-white rounded-full p-1 ml-4 hover:bg-red-700"
                               onClick={() => handleDeletePartner(partner)}
                           >
                               <TrashIcon className="h-6 w-6" />
                           </button>
                       </>
                       )}
                   </div>
               ))}
           </div>

           <div className="flex justify-center mt-10">
               {authenticated ? (
                   <Link to='/addpartners'>
                       <button className="bg-gren rounded font-montserrat p-2 flex items-center justify-center">
                           <PlusIcon className="h-6 w-6 mr-2" />
                           Add Partners
                       </button>
                   </Link>
               ) : (
                   <div></div>
               )}

               {showSponsorModal && (
                   <div className="fixed z-10 inset-0 overflow-y-auto">
                       <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                           <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                               <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                           </div>
                           <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                           <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                               <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                   <div className="sm:flex sm:items-start">
                                       <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                           <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                       </div>
                                       <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                           <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                               Delete Sponsor
                                           </h3>
                                           <div className="mt-2">
                                               <p className="text-sm text-gray-500">
                                                   Are you sure you want to delete this sponsor? This action cannot be undone.
                                               </p>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                   <button
                                       type="button"
                                       className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                                       onClick={confirmDeleteSponsor}
                                   >
                                       Delete
                                   </button>
                                   <button
                                       type="button"
                                       className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                                       onClick={() => setShowSponsorModal(false)}
                                   >
                                       Cancel
                                   </button>
                               </div>
                           </div>
                       </div>
                   </div>
               )}
               {showModal && (
                   <div className="fixed z-10 inset-0 overflow-y-auto">
                       <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                           <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                               <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                           </div>
                           <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                           <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                               <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                   <div className="sm:flex sm:items-start">
                                       <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                           <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                       </div>
                                       <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                           <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                               Delete Partner
                                           </h3>
                                           <div className="mt-2">
                                               <p className="text-sm text-gray-500">
                                                   Are you sure you want to delete this partner? This action cannot be undone.
                                               </p>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                   <button
                                       type="button"
                                       className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                                       onClick={confirmDeletePartner}
                                   >
                                       Delete
                                   </button>
                                   <button
                                       type="button"
                                       className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                                       onClick={() => setShowModal(false)}
                                   >
                                       Cancel
                                   </button>
                               </div>
                           </div>
                       </div>
                   </div>
               )}
           </div>
       </div >
   );
}