import React, { useRef, useState, useEffect, forwardRef } from 'react';
import { db, storage } from '../firebase'; // Ensure firebase is properly configured
import { useNavigate, Link } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { collection, addDoc, getDocs } from 'firebase/firestore';

const AddOpportunities = () => {
    const [flyer, setFlyer] = useState(null);
    const [title, setTitle] = useState("");
    const [oppLink, setOppLink] = useState("");
    const [department, setDepartment] = useState("");
    const [topic, setTopic] = useState("");
    const [type, setType] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [endTime, setEndTime] = useState("");
    const [opportunities, setOpportunities] = useState([]);
    const [partners, setPartners] = useState({});


    const navigate = useNavigate();
    const fileInputRef = useRef(null);

    useEffect(() => {
        const fetchOpportunities = async () => {
            const querySnapshot = await getDocs(collection(db, 'opportunities'));
            const opportunitiesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setOpportunities(opportunitiesData);
        };

        const fetchPartners = async () => {
            const querySnapshot = await getDocs(collection(db, 'partners'));
            const partnersData = querySnapshot.docs.reduce((acc, doc) => {
                acc[doc.data().name] = {
                    logo: doc.data().logoURL,
                    url: doc.data().link,
                    department: doc.data().name
                };
                return acc;
            }, {});
            console.log(partnersData);
            setPartners(partnersData);
        };

        fetchOpportunities();
        fetchPartners();
    }, []);

    const uploadFile = async () => {
        console.log("uploadFile function called"); // Log function call

        try {
            let flyerDownloadURL = "";
            if (flyer) {
                const flyerUniqueName = `${uuidv4()}-${flyer.name}`;
                const flyerRef = ref(storage, `opportunities/${flyerUniqueName}`);
                await uploadBytes(flyerRef, flyer);
                console.log('Flyer uploaded successfully'); // Log success
                flyerDownloadURL = await getDownloadURL(flyerRef);
            }

            const { logo: logoURL, url: departmentURL } = partners[department]; // Get logo URL and department URL based on department

            const docRef = await addDoc(collection(db, 'opportunities'), {
                logoURL,
                flyerURL: flyerDownloadURL,
                department,
                title,
                oppLink,
                topic,
                type,
                startDate,
                endDate,
                endTime,
                departmentURL
            });

            console.log('Document written with id: ', docRef.id);
            fileInputRef.current.value = "";
            setFlyer(null);
            navigate('/Opportunities');
        } catch (error) {
            console.error('Error uploading file: ', error); // Log errors
        }
    };

    const Dropdown = ({ label, value, options, onChange, id }) => (
        <label className='block text-lg font-montserrat mb-2'>
            {label}
            <select value={value} onChange={onChange} className='w-full p-2 border rounded font-montserrat' id={id}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </label>
    );
    

    return (
        <form
            className='flex flex-col items-center bg-gray-100 p-6 mt-10 rounded shadow-md'
            onSubmit={(e) => {
                e.preventDefault();
                console.log('Form submitted'); // Log form submission
                uploadFile();
            }}
        >
            <h2 className='text-2xl font-montserrat font-bold mb-4'>Add Opportunities</h2>
            <div className='w-full mb-4'>
                <Dropdown
                    label="Organization:"
                    options={[
                        { label: 'Select organization', value: "" },
                        ...Object.keys(partners).map(key => ({ label: key, value: key })),
                    ]}
                    value={department}
                    onChange={(event) => setDepartment(event.target.value)}
                    id='department'
                />
            </div>

            <div className='w-full mb-4'>
                <label htmlFor="title" className='block text-lg font-montserrat mb-2'>Opportunity Title:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="text"
                    id="title"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                />
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="oppLink" className='block text-lg font-montserrat mb-2'>Opportunity Link:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="text"
                    id="oppLink"
                    value={oppLink}
                    onChange={(event) => setOppLink(event.target.value)}
                />
            </div>

            <div className='w-full mb-4'>
                <label htmlFor="topic" className='block text-lg font-montserrat mb-2'>Topic:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="text"
                    id="topic"
                    value={topic}
                    onChange={(event) => setTopic(event.target.value)}
                />
            </div>
            <div className='w-full mb-4'>
                <Dropdown
                    label="Opportunity Type:"
                    options={[
                        { label: 'Select type of opportunity', value: "" },
                        { label: 'Competition', value: 'Competition' },
                        { label: 'Program', value: 'Program' },
                        { label: 'Other', value: 'Other' },
                    ]}
                    value={type}
                    onChange={(event) => setType(event.target.value)}
                    id='type'
                />
            </div>
            <div className="grid grid-cols-2 gap-16 justify-start">
                <div>
                    <label htmlFor="startDate" className="font-montserrat block text-gray-700 font-medium mb-2">
                        Start Date:
                    </label>
                    <input
                        type="date"
                        className="w-full p-2 mb-4 border border-gray-300 rounded"
                        id="startDate"
                        value={startDate}
                        onChange={(event) => setStartDate(event.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="endDate" className="font-montserrat block text-gray-700 font-medium mb-2">
                        Open Until:
                    </label>
                    <div className='flex flex-row'>
                        <input
                            type="date"
                            className="w-full p-2 mb-4 border border-gray-300 rounded"
                            id="endDate"
                            value={endDate}
                            onChange={(event) => setEndDate(event.target.value)}
                        />
                        <input
                            type="time"
                            className="w-full p-2 mb-4 border border-gray-300 rounded"
                            id="endTime"
                            value={endTime}
                            onChange={(event) => setEndTime(event.target.value)}
                        />
                    </div>

                </div>
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="flyer" className='block text-lg font-montserrat mb-2'>Opportunity Flyer:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="file"
                    id="flyer"
                    name="flyer"
                    ref={fileInputRef}
                    onChange={(event) => {
                        setFlyer(event.target.files[0]);
                        console.log('Flyer selected: ', event.target.files[0]); // Log file selection
                    }}
                />
            </div>
            <button
                type="submit"
                className='bg-gren text-white p-2 rounded font-montserrat hover:bg-green-700'
            >
                Submit
            </button>
        </form>
    );
}

export default AddOpportunities;