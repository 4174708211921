import React, { useEffect, useState } from 'react';
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';

export default function About() {
    const [aboutData, setAboutData] = useState([]);
    const [authenticated, setAuthenticated] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setAuthenticated(true);
                const docRef = doc(db, 'profiles', user.uid);
                const docSnap = await getDoc(docRef);
                if(docSnap.exists() && docSnap.data().isAdmin) {
                    setIsAdmin(true);
                }
            } else {
                setAuthenticated(false);
                setIsAdmin(false);
            }
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchAbout = async () => {
            const querySnapshot = await getDocs(collection(db, 'about'));
            const about = [];
            querySnapshot.forEach((doc) => {
                about.push({ id: doc.id, ...doc.data() });
            });
            setAboutData(about);
        };
        fetchAbout();
    }, []);

    return (
        <div>
            <section className="flex bg-grey w-full h-48">
                <p className="m-auto text-navy font-montserrat font-bold text-center text-5xl">
                    About NExUS
                </p>
            </section>
            <div className="flex justify-end mt-5 mr-5">
                {authenticated && isAdmin && (
                    <Link to="/EditAbout">
                        <button className="bg-sky-500 rounded font-montserrat p-2 flex items-center justify-center m-3 hover:text-white">
                            <PencilSquareIcon className="h-6 w-6 mr-2" />
                            Edit
                        </button>
                    </Link>
                )}
            </div>
            {aboutData.map((about, index) => (
                <div key={index}>
                    <h1 className='text-org font-bold font-montserrat text-center text-2xl mt-10'>
                        {about.subtitle}
                    </h1>
                    <h2 className='font-montserrat text-center text-medium mt-10 px-24'>
                        {about.description.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </h2>
                </div>
            ))}
        </div>
    );
}
