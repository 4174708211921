import React from 'react';
import { PencilSquareIcon, PlusIcon, TrashIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import { auth, db, functions } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, getDocs, setDoc, deleteDoc } from 'firebase/firestore';
import { useAuth } from '../auth/AuthContext';
import { createUserWithEmailAndPassword, deleteUser } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';


const ManageUsers = () => {
    const { currentUser, isAdmin } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            const usersCollection = collection(db, 'profiles');
            const userSnapshot = await getDocs(usersCollection);
            const userList = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setUsers(userList);
        };
        fetchUsers();
    }, []);

    const addUser = async (email, password) => {
        const addUserFunction = httpsCallable(functions, 'addUser');
        try {
            const result = await addUserFunction({ email, password });
            console.log('User added:', result.data);
        } catch (error) {
            console.error('Error adding user:', error);
            setError(error.message);
        }
    };

    const deleteUser = async (uid) => {
        const deleteUserFunction = httpsCallable(functions, 'deleteUser');
        try {
            const result = await deleteUserFunction({ uid });
            console.log('User deleted:', result.data);
        } catch (error) {
            console.error('Error deleting user:', error);
            setError(error.message);
        }
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        setEmail('');
        setPassword('');
        setError(null);
        await addUser(email, password);
    };

    const handleRemoveUser = async (e) => {
        e.preventDefault();
        setSelectedUser('');
        setError(null);
        await deleteUser(selectedUser);
    };

    return (
        <div>
            <div className="flex flex-col justify-center mt-20 mb-24 grid grid-cols-2">
                {currentUser && isAdmin ? (
                    <>
                        <div className="flex justify-center p-5 rounded">
                            <div className="w-96 flex flex-col items-center justify-center">
                                <label
                                    htmlFor="email-input"
                                    className="block w-96 mb-2 text-lg font-medium text-gray-900 dark:text-white"
                                >
                                    Email
                                </label>
                                <div className="relative mb-4 w-96">
                                    <input
                                        type="text"
                                        id="email-input"
                                        value={email}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Enter email (to add)"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <label
                                    htmlFor="password-input"
                                    className="block w-96 mb-2 text-lg font-medium text-gray-900 dark:text-white"
                                >
                                    Password
                                </label>

                                <div className="relative mb-4 w-96">
                                    <input
                                        type="text"
                                        id="password-input"
                                        value={password}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Enter password (to add)"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <button
                                    onClick={handleAddUser}
                                    className="bg-gren rounded font-montserrat p-2 flex items-center justify-center hover:text-white m-5"
                                >
                                    <PlusIcon className="h-6 w-6 mr-2" />
                                    Add User
                                </button>

                                {error && <p className="text-red-500">{error}</p>}
                            </div>
                            
                        </div>
                        <div className=''>
                            <div className='flex justify-center py-5'>
                                <div className='w-96 flex flex-col items-center justify-center'>
                                <label
                                htmlFor="uid-input"
                                className="block w-96 mb-2 text-lg font-medium text-gray-900 dark:text-white"
                            >
                                User to Remove
                            </label>
                            <div className="relative mb-4 w-96">
                                <select
                                    type="user-select"
                                    value={selectedUser}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    onChange={(e) => setSelectedUser(e.target.value)}
                                >
                                    <option value="">Select user</option>
                                    {users.map(user => (
                                        <option key={user.id} value={user.id}>
                                            {user.name} ({user.email})
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button
                                onClick={handleRemoveUser}
                                className="bg-red-400 rounded font-montserrat p-2 flex items-center justify-center hover:text-white m-5"
                            >
                                <TrashIcon className="h-6 w-6 mr-2" />
                                Remove User
                            </button>
                                </div>
                                
                            </div>
                            
                        </div>
                    </>
                ) : (
                    <div>You are not an admin</div>
                )}
            </div>
        </div>
    );
};

export default ManageUsers;

