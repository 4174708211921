import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import NSIN from '../pics/NSIN_Logo_RGB_Full-Lock-Up-2-Color.webp';
import pfp from '../pics/defaultpfp.png';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import './events.css';

const Tabs = ({ children }) => {
    const [activeTab, setActiveTab] = useState(children && children.length > 0 ? children[0].props.label : '');
    const handleClick = (e, newActiveTab) => {
        e.preventDefault();
        setActiveTab(newActiveTab);
    };

    if (!children || children.length === 0) return null;

    return (
        <div className="w-full mx-auto p-5">
            <div className="flex border-b border-gray-300">
                {children.sort().map(child => (
                    <button
                        key={child.props.label}
                        className={`flex-1 text-gray-700 font-medium py-2 px-4 border-r font-montserrat ${activeTab === child.props.label ? 'border-b-2 border-org' : ''}`}
                        onClick={e => handleClick(e, child.props.label)}
                    >
                        {child.props.label}
                    </button>
                ))}
            </div>
            <div className="py-4">
                {children.map(child => {
                    if (child.props.label === activeTab) {
                        return <div key={child.props.label}>{child.props.children}</div>;
                    }
                    return null;
                })}
            </div>
        </div>
    );
};

const Tab = ({ label, children }) => {
    return (
        <div label={label} className="hidden">
            {children}
        </div>
    );
};

const Organizations = () => {
    const [userId, setUserId] = useState(null);
    const [profiles, setProfiles] = useState({});
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [partners, setPartners] = useState({});
    const [opportunities, setOpportunities] = useState([]);
    const [department, setDepartments] = useState([]);

    const navigate = useNavigate();

    const EventModal = ({ isOpen, closeModal, event }) => {
        if (!isOpen) return null;

        return (
            <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg w-3/4">
                    <h2 className="text-2xl font-bold mb-4">{event.title}</h2>
                    <p className="text-lg mb-4">{event.description}</p>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg" onClick={closeModal}>
                        Close
                    </button>
                </div>
            </div>
        );
    };

    const openModal = (event) => {
        setSelectedEvent(event);
    };

    const closeModal = () => {
        setSelectedEvent(null);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserId(user.uid);
                loadProfiles();
            } else {
                setUserId(null);
            }
        });

        const fetchEvents = async () => {
            const eventsCollection = collection(db, 'calendar');
            const eventsSnapshot = await getDocs(eventsCollection);
            const eventsList = eventsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                start: doc.data().start.toDate(),
                end: doc.data().end.toDate()
            }));
            setEvents(eventsList);
        };

        const fetchOpportunities = async () => {
            const querySnapshot = await getDocs(collection(db, 'opportunities'));
            const opportunitiesData = [];
            querySnapshot.forEach((doc) => {
                opportunitiesData.push({ id: doc.id, ...doc.data() });
            })
            setOpportunities(opportunitiesData);
        }
        fetchOpportunities();

        const fetchPartners = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'partners'));
                const partnersData = querySnapshot.docs.reduce((acc, doc) => {
                    const name = doc.data().name || 'Other';
                    acc[name] = {
                        department: doc.data().name || 'Other',
                        logo: doc.data().logoURL,
                        url: doc.data().link,
                    };
                    return acc;
                }, {});

                console.log('Fetched Partners:', partnersData); // Debug statement to log partners data

                setPartners(partnersData);

                // Sort departments alphabetically and ensure "Other" is at the end
                const sortedDepartments = [...Object.keys(partnersData)].sort((a, b) => {
                    if (a === 'Other') return 1;
                    if (b === 'Other') return -1;
                    return a.localeCompare(b);
                });

                setDepartments(sortedDepartments);
            } catch (error) {
                console.error('Error fetching partners:', error);
            }
        };


        fetchEvents();
        fetchPartners();

        return () => unsubscribe();
    }, []);

    const loadProfiles = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'profiles'));
            const profilesData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            const groupedProfiles = profilesData.reduce((acc, profile) => {
                const department = profile.department || 'Other';
                if (!acc[department]) {
                    acc[department] = [];
                }
                acc[department].push(profile);
                return acc;
            }, {});

            console.log("Grouped Profiles:", groupedProfiles); // Debug line to check profiles data
            setProfiles(groupedProfiles);
        } catch (error) {
            console.error('Error loading profiles: ', error);
        }
    };

    const sortedDepartments = [...Object.keys(partners)].sort((a, b) => {
        if (a === 'Other') return 1;
        if (b === 'Other') return -1;
        return a.localeCompare(b);
    });

    const ensureAbsoluteUrl = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            return `https://${url}`;
        }
        return url;
    };

    const getColorClass = (eventType) => {
        switch (eventType) {
            case 'Workshop':
                return 'Industry';
            case 'Experimentation':
                return 'Startups';
            case 'Opportunities':
                return 'Opportunities';
            case 'Outreach':
                return 'Academia';
            case 'Conference':
                return 'Funding';
            case 'Other':
                return 'Other';
            default:
                return 'bg-grey';
        }
    };

    const handleProfileClick = (profileId) => {
        if (profileId === userId) {
            navigate('/Profile');
        } else {
            navigate(`/Profile/${profileId}`);
        }
    };

    return (
        <div>
            <section className="flex bg-grey w-full h-48">
                <p className="m-auto text-navy font-montserrat font-bold text-center text-5xl">
                    ORGANIZATIONS
                </p>
            </section>
            <Tabs>
                {sortedDepartments.map(name => (
                    <Tab key={name} label={name}>
                        <div className='grid grid-cols-3'>
                            <div className="py-8 bg-gray-100 px-8 rounded">
                                {partners[name] && (
                                    <div className='w-full justify-center text-center flex'>
                                        <img src={partners[name].logo} alt='' />
                                    </div>
                                )}
                                <section className="inline-flex items-center justify-start w-full relative mt-6">
                                    <hr className="w-full h-1 my-8 bg-navy border-0 rounded dark:bg-gray-700" />
                                    <div className="absolute px-4 bg-gray-100">
                                        <h1 className="text-navy font-montserrat font-bold text-2xl">Profiles</h1>
                                    </div>
                                </section>
                                <div className='text-start'>
                                    {profiles[partners[name].department]?.map(profile => (
                                        <div key={profile.id} className='bg-white border border-b-8 border-r-4 rounded flex justify-start mb-3'>
                                            <div className='grid grid-cols-3 gap-2 my-1'>
                                                <div className='col-span-1'>
                                                    <img src={profile.logoURL || pfp} className='rounded-full object-cover object-center h-32 w-32 ml-1 mt-3 mb-3'
                                                        style={{ aspectRatio: '1 / 1' }}
                                                        alt="" />
                                                </div>
                                                <div className='flex justify-center flex-col font-montserrat col-span-2 overflow-wrap'>
                                                    <div
                                                        className='font-bold text-xl mb-1 cursor-pointer hover:text-org text-center'
                                                        onClick={() => handleProfileClick(profile.id)}
                                                    >
                                                        {profile.name}
                                                    </div>
                                                    <h2 className='mb-4 text-lg text-center'>{profile.role}</h2>
                                                    <h3 className='text-sm mb-2  text-center overflow-wrap'>{profile.email}</h3>
                                                    <h3 className='text-medium mb-2 text-center'>{profile.number}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {!profiles[partners[name].department] && (
                                        <p className='font-montserrat text-center'>No profiles found for this department.</p>
                                    )}
                                </div>
                            </div>

                            <div className='col-span-2 m-5'>
                                <div className="inline-flex items-center justify-center w-full relative">
                                    <hr className="w-full h-1 my-5 bg-navy border-0 ml-5" />
                                    <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2">
                                        <h1 className="text-navy font-montserrat font-bold text-center text-3xl">Events</h1>
                                    </div>
                                </div>
                                <div className='grid grid-cols-3 gap-4 mt-5 text-center p-3 mb-8'>
                                    {events
                                        .filter(event => event.departments && event.departments.includes(name))
                                        .map(event => (
                                            <div key={event.id} className={`rounded p-4 ${getColorClass(event.type)} border border-b-8 border-r-8`}>
                                                <div className="col-span-3 text-center mx-2 font-montserrat text-xl font-bold m-2 mb-4">{event.title}</div>
                                                <div className="col-span-3 text-start mx-2 font-montserrat text-m font-regular mb-2"><strong>Location:</strong> {event.location}</div>
                                                <div className="col-span-3 text-start mx-2 font-montserrat text-m font-regular mb-2"><strong>When: </strong>{format(event.start, 'yyyy-MM-dd HH:mm')} - {format(event.end, 'yyyy-MM-dd HH:mm')}</div>
                                                <div className="col-span-3 text-start mx-2 font-montserrat text-m font-regular mb-2"><strong>Point of contact: </strong>{event.people}</div>
                                                <div className="col-span-3 text-start mx-2 font-montserrat text-m font-regular mb-2"><strong>Type: </strong>{event.type}</div>
                                                <div className="col-span-3 text-start mx-2 font-montserrat text-m font-regular mb-2">
                                                    <strong>Description: </strong>{event.description.length > 100 ? `${event.description.substring(0, 100)}...` : event.description}
                                                    {event.description.length > 100 && (
                                                        <button onClick={() => openModal(event)} className="text-blue-500 hover:underline ml-1">Read more</button>
                                                    )}
                                                </div>
                                                {event.flyerURL && (
                                                    <p>
                                                        <a href={event.flyerURL} target="_blank" rel="noopener noreferrer" className="text-blue-500">View Flyer</a>
                                                    </p>
                                                )}
                                            </div>
                                        ))
                                    }
                                    {!events && (
                                        <p className='font-montserrat text-center'>No events found for this department.</p>
                                    )}
                                </div>
                                <div className="inline-flex items-center justify-center w-full relative ">
                                    <hr className="w-full h-1 my-5 bg-navy border-0 ml-5 " />
                                    <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2">
                                        <h1 className="text-navy font-montserrat font-bold text-center text-3xl">Opportunities</h1>
                                    </div>
                                </div>
                                <div className='grid grid-cols-3 gap-4 mt-5 text-center p-3'>
                                    {opportunities
                                        .filter(opportunity => opportunity.department === partners[name].department)
                                        .map((opportunity, index) => (
                                            <div key={index} className="bg-orange-200 rounded border border-b-4 border-orange-300 border-r-4">
                                                <a href={ensureAbsoluteUrl(opportunity.oppLink)} target="_blank" rel="noopener noreferrer">
                                                    <div className="grid grid-cols-3 gap-4 m-5">
                                                        <div className="col-span-2 mx-2 text-start font-montserrat font-bold text-xl hover:text-org">
                                                            <a href={ensureAbsoluteUrl(opportunity.oppLink)} target="_blank" rel="noopener noreferrer">{opportunity.title}</a>
                                                        </div>
                                                        <div className="col-span-3 text-start mx-2 font-montserrat text-m font-regular">{opportunity.organization}</div>
                                                        <div className="col-span-3 text-start mx-2 font-montserrat text-m font-regular">Topic: {opportunity.topic}</div>
                                                        <div className="col-span-3 text-start mx-2 font-montserrat text-m font-regular">Type: {opportunity.type}</div>
                                                        <div className="col-span-3 text-start mx-2 font-montserrat text-m font-regular">Open until: {opportunity.endDate}</div>
                                                        <a
                                                            href={opportunity.logoURL}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="col-span-3 text-start mx-2 font-montserrat text-lg font-regular text-blue-500 hover:underline"
                                                        >
                                                            View Flyer
                                                        </a>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                        <EventModal isOpen={selectedEvent !== null} closeModal={closeModal} event={selectedEvent} />
                    </Tab>
                ))}
            </Tabs>
        </div>
    );

};

export default Organizations;
