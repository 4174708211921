import React from 'react';
import home from "../pics/home.png"
import { Link } from 'react-router-dom';

export default function Homepage() {
   return (
       <div>
           <img src={home} className="h-80 w-full object-cover"></img>
           <div className='font-montserrat font-bold text-lg text-center mt-10 px-72'>
               NExUS is a collaborative initiative to energize the New England technology community to formulate innovation solutions addressing critical multi-service warfighter problems.
               <br></br><br></br>
               The aim is to facilitate and foster information-sharing and relationships between the DoD, academia, state governments, non-profits and industry innovators in the NE region to bridge the gaps in understanding DoD innovation needs and requirements and increase the speed at which cutting-edge technological solutions reach the warfighter.
           </div>
           <div className='flex flex-col items-center mt-8'>
               <Link to="/About"><button className='bg-navy rounded text-white py-1 px-4 font-bold text-medium font-montserrat'>Learn More</button></Link>

           </div>
       </div>
   );
}