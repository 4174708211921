
import React, { useState, useRef, useEffect } from 'react';
import pfp from '../pics/defaultpfp.png';
import { AiOutlineCaretUp, AiOutlineCaretDown } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { db, storage, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

import Authentication from '../auth/Authentication';
import { NavLink, useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { collection, addDoc, doc, getDoc, setDoc, getDocs } from 'firebase/firestore';
import ChangePassword from './ChangePassword';


// Sample options for the dropdown
const options = [
    { value: 'AI', label: 'AI' },
    { value: 'Counter UAVs', label: 'Counter UAVs' },
    { value: 'Nutrition', label: 'Nutrition' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Cybersecurity', label: 'Cybersecurity' },
    { value: 'Medicine', label: 'Medicine' },
    { value: 'Biometrics', label: 'Biometrics' },
    { value: 'Biotechnology', label: 'Biotechnology' },
    { value: 'Quantum Science', label: 'Quantum Science' },
    { value: 'Future Generation Wireless Technology (FutureG)', label: 'Future Generation Wireless Technology (FutureG)' },
    { value: 'Advanced Materials', label: 'Advanced Materials' },
    { value: 'Trusted AI and Autonomy', label: 'Trusted AI and Autonomy' },
    { value: 'Integrated Network Systems-of-Systems', label: 'Integrated Network Systems-of-Systems' },
    { value: 'Microelectronics', label: 'Microelectronics' },
    { value: 'Space Technology', label: 'Space Technology' },
    { value: 'Renewable Energy Generation and Storage', label: 'Renewable Energy Generation and Storage' },
    { value: 'Advanced Computing and Software', label: 'Advanced Computing and Software' },
    { value: 'Human-Machine Interfaces', label: 'Human-Machine Interfaces' },
    { value: 'Directed Energy', label: 'Directed Energy' },
    { value: 'Hypersonics', label: 'Hypersonics' },
    { value: 'Integrated Sensing and Cyber', label: 'Integrated Sensing and Cyber' },
    { value: 'other', label: 'Other' }
];

export default function Profile() {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [customOption, setCustomOption] = useState('');
    const [logo, setLogo] = useState(null);
    const [name, setName] = useState('');
    const [logoURL, setLogoURL] = useState(null);
    const [role, setRole] = useState('');
    const [biography, setBiography] = useState('');
    const [expertise, setExpertise] = useState('');
    const [department, setDepartment] = useState('');
    const [number, setNumber] = useState('');
    const [partners, setPartners] = useState({});


    const [userId, setUserId] = useState(null);
    const [userEmail, setUserEmail] = useState('');

    const [saveButtonText, setSaveButtonText] = useState('Save');
    const [saveButtonColor, setSaveButtonColor] = useState('bg-navy');

    const [isModalOpen, setIsModalOpen] = useState(false);

    const fileInputRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserId(user.uid);
                setUserEmail(user.email || 'email here');
                loadProfile(user.uid);
            } else {
                setUserId(null);
            }
        })
        const fetchPartners = async () => {
            const querySnapshot = await getDocs(collection(db, 'partners'));
            const partnersData = querySnapshot.docs.reduce((acc, doc) => {
                acc[doc.data().name] = {
                    logo: doc.data().logoURL,
                    url: doc.data().link,
                };
                return acc;
            }, {});
            setPartners(partnersData);
        };
        fetchPartners();

        return () => unsubscribe();
    }, []);

    const loadProfile = async (uid) => {
        try {
            const docRef = doc(db, 'profiles', uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                setLogoURL(data.logoURL || pfp);
                setRole(data.role);
                setDepartment(data.department || '');
                setBiography(data.biography);
                setSelectedOptions(data.areasOfInterest);
                setExpertise(data.expertise);
                setName(data.name);
                setNumber(data.number);
                if (data.customOption) setCustomOption(data.customOption);
            }
        } catch (error) {
            console.error('Error loading profile: ', error);
        }
    }

    const saveProfile = async () => {
        try {
            let uploadedLogoURL = logoURL;
            if (logo) {
                const uniqueName = `${uuidv4()}-${logo.name}`;
                const logoRef = ref(storage, `profiles/${uniqueName}`);
                await uploadBytes(logoRef, logo);
                uploadedLogoURL = await getDownloadURL(logoRef);
            }

            const profileData = {
                logoURL: uploadedLogoURL,
                name: name,
                role: role,
                department: department,
                biography: biography,
                areasOfInterest: selectedOptions,
                customOption: customOption,
                expertise: expertise,
                number: number
            }

            await setDoc(doc(db, 'profiles', userId), profileData);
            console.log('Profile saved successfully');
            setSaveButtonText('Saved!');
            setSaveButtonColor('bg-green-500');

            setTimeout(() => {
                setSaveButtonText('Save');
                setSaveButtonColor('bg-navy');
            }, 2000);

        } catch (error) {
            console.error('Error saving profile: ', error);
        }
    }

    const handleOptionChange = (option) => {
        if (option === 'other') {
            if (selectedOptions.includes(option)) {
                setSelectedOptions(selectedOptions.filter(item => item !== option));
            } else {
                setSelectedOptions([...selectedOptions, option]);
            }
        } else {
            if (selectedOptions.includes(option)) {
                setSelectedOptions(selectedOptions.filter(item => item !== option));
            } else {
                setSelectedOptions([...selectedOptions, option]);
            }
        }
    };

    const handleCustomOptionChange = (e) => {
        setCustomOption(e.target.value);
    };

    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const handleTagRemove = (option) => {
        setSelectedOptions(selectedOptions.filter(item => item !== option));
        if (option === 'other') {
            setCustomOption('');
        }
    };

    const Dropdown = ({ label, value, options, onChange, id }) => (
        <label className='block mb-2 mt-2'>
            <select value={value} onChange={onChange} className='placeholder-gray-500 bg-grey justify-center border rounded font-montserrat' id={id}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </label>
    );

    return (
        <div className='grid grid-cols-3'>
            <div className='col-span-1 flex flex-col items-start ml-10 mt-10'>
                <h1 className='font-montserrat font-bold text-2xl mb-10'>PROFILE</h1>

                <div className='flex flex-col items-center w-full justify-center'>
                    <img
                        src={logoURL}
                        className='rounded-full object-cover object-center h-48 w-48 mb-2'
                        style={{ aspectRatio: '1 / 1' }} // Maintain a 1:1 aspect ratio
                        alt=""
                    />

                    <div className='w-full  flex justify-center'>
                        <label htmlFor="logo" className='text-md font-montserrat'>Add profile picture:</label>
                        <input
                            className='hidden '
                            type="file"
                            id="logo"
                            name="logo"
                            ref={fileInputRef}
                            onChange={(event) => {
                                setLogo(event.target.files[0]);
                            }}
                        />
                        <button
                            className='bg-grey hover:bg-gray-500 text-black ml-2 px-1 rounded text-sm font-montserrat'
                            onClick={() => fileInputRef.current.click()}
                        >
                            Choose File
                        </button>
                    </div>

                    <div className='flex w-full justify-center'>
                        <input
                            className='placeholder-gray-500 bg-grey w-1/2 pl-2 justify-center border rounded font-montserrat mt-2 mb-1'
                            type="text"
                            id="name"
                            value={name}
                            placeholder='Input name here'
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>

                    <div className='flex w-full justify-center'>
                        <input
                            className='placeholder-gray-500 bg-grey w-1/2 pl-2 justify-center border rounded font-montserrat mb-1'
                            type="text"
                            id="role"
                            value={role}
                            placeholder='Input role here'
                            onChange={(e) => setRole(e.target.value)}
                        />
                    </div>

                    <div className='flex w-full justify-center'>
                        <Dropdown
                            label="Organization:"
                            options={[
                                { label: 'Select organization', value: "" },
                                ...Object.keys(partners).map(key => ({ label: key, value: key })),
                            ]}
                            value={department}
                            onChange={(event) => setDepartment(event.target.value)}
                            id='department'
                        />
                    </div>
                    <h2 className='font-montserrat text-md mb-1'>{userEmail}</h2>
                    <div className='flex w-full justify-center'>
                        <input
                            className='placeholder-gray-500 bg-grey w-1/2 pl-2 justify-center border rounded font-montserrat mb-1'
                            type="text"
                            id="number"
                            value={number}
                            placeholder='Input phone number'
                            onChange={(e) => setNumber(e.target.value)}
                        />
                    </div>
                    <button
                        className="text-[#e47c44] bg-white font-montserrat font-bold hover:text-[#d65f1f] px-4 py-2 rounded mt-4"
                        onClick={() => setIsModalOpen(true)} // Open the modal
                    >
                        Change Password
                    </button>
                    <Link to='/Directory' className='font-montserrat text-xl text-org font-bold mt-20 hover:text-navy mb-20 '>SEE NExUS DIRECTORY</Link>
                </div>
            </div>

            <div className='col-span-2 flex flex-col mt-20 ml-5'>
                <h1 className='font-montserrat font-bold text-lg'>Biography</h1>
                <textarea
                    className='bg-grey rounded mr-10 mt-2 h-24 p-2 font-montserrat'
                    value={biography}
                    onChange={(e) => setBiography(e.target.value)}
                />
                <h1 className='font-montserrat font-bold text-lg mt-8'>Areas of Development</h1>
                <div className='relative inline-block w-64'>
                    <button onClick={handleDropdownToggle}
                        className='font-montserrat w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-between'>
                        Select options
                        {showDropdown ? (
                            <AiOutlineCaretUp className="h-3 ml-2" />
                        ) : (
                            <AiOutlineCaretDown className="h-3 ml-2" />
                        )}
                    </button>
                    {showDropdown &&
                        <div className='overflow-scroll h-56 absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded shadow-lg font-montserrat'>
                            {options.map(option => (
                                <label key={option.value} className='flex items-center p-2 hover:bg-gray-100 cursor-pointer'>
                                    <input
                                        type='checkbox'
                                        className='form-checkbox'
                                        checked={selectedOptions.includes(option.value)}
                                        onChange={() => handleOptionChange(option.value)}
                                    />
                                    <span className='ml-2'>{option.label}</span>
                                    {option.value === 'other' && selectedOptions.includes(option.value) && (
                                        <input
                                            type='text'
                                            value={customOption}
                                            onChange={handleCustomOptionChange}
                                            className='ml-2 w-36 pl-1 border rounded'
                                            placeholder='Please specify'
                                        />
                                    )}
                                </label>
                            ))}
                        </div>
                    }
                </div>
                <div className='mt-4 flex flex-wrap'>
                    {selectedOptions.map(option => (
                        <div key={option} className='inline-flex items-center bg-blue-200 text-blue-800 rounded-full px-3 py-1 m-1'>
                            <span>
                                {option === 'other' ? customOption : options.find(o => o.value === option).label}
                            </span>
                            <XCircleIcon
                                onClick={() => handleTagRemove(option)}
                                className='ml-2 h-5 w-5 text-red-600 cursor-pointer'
                            />
                        </div>
                    ))}
                </div>
                <h1 className='font-montserrat font-bold text-lg mt-5'>Areas of Expertise</h1>
                <textarea
                    className='bg-grey rounded mr-10 mt-2 h-12 p-2 font-montserrat'
                    value={expertise}
                    onChange={(e) => setExpertise(e.target.value)}
                />
                <div className='flex justify-end mr-8 mt-12'>
                    <button
                        className={`m-2 rounded text-white font-montserrat font-bold p-2 px-4 hover:bg-org ${saveButtonColor}`}
                        onClick={saveProfile}
                    >
                        {saveButtonText}
                    </button>
                </div>
            </div>
            <ChangePassword isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </div>
    );
}
