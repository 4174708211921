import React, { useState, useEffect } from "react";
import { AiOutlineCaretUp, AiOutlineCaretDown } from "react-icons/ai";
import Shawn from "../pics/shawn.png";
import { Link } from 'react-router-dom';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import pfp from '../pics/defaultpfp.png';
import { useAuth } from '../auth/AuthContext';


function Dropdownpls({ isOpen, setIsOpen }) {
   const [userName, setUserName] = useState("");
   const [profilePic, setProfilePic] = useState(pfp);
   const [loading, setLoading] = useState(true);
   const { currentUser, isAdmin } = useAuth();

   useEffect(() => {
    const fetchUserData = async () => {
        if (currentUser) {
            try {
                const docRef = doc(db, 'profiles', currentUser.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setUserName(data.name || "You");
                    if (data.logoURL) {
                        setProfilePic(data.logoURL);
                    }
                }
            } catch (error) {
                console.error("Error fetching user data: ", error);
            }
            setLoading(false);
        }
    };
    fetchUserData();
}, [currentUser]);

   const handleLinkClick = () => {
       setIsOpen(false);
   };

   return (
       <div className="relative">
           <button
               onClick={() => setIsOpen((prev) => !prev)}
               className="font-montserrat font-bold text-medium border-b-4 border-r-2 mr-3 hover:bg-grey active:bg-grey focus:ring-2 focus:outline-none focus:ring-grey font-bold rounded-lg p-2 flex items-center"
           >
               <img src={profilePic} className="mr-2 w-8 h-8 rounded-full object-cover" alt={userName} />
               {userName}{" "}
               {!isOpen ? (
                   <AiOutlineCaretDown className="h-3 ml-2" />
               ) : (
                   <AiOutlineCaretUp className="h-3 ml-2" />
               )}
           </button>
           {isOpen && (
               <div className="absolute top-full left-0 p-2 flex flex-col items-start rounded font-montserrat bg-white shadow-md text-medium z-50">
                   <Link to="/Profile" className="hover:bg-gray-100 hover:rounded py-1 px-2 cursor-pointer" onClick={handleLinkClick}>Profile</Link>
                   <Link to="/Organizations" className="hover:bg-gray-100 hover:rounded py-1 px-2 cursor-pointer" onClick={handleLinkClick}>Organizations</Link>
                   <Link to="/Directory" className="hover:bg-gray-100 hover:rounded py-1 px-2 cursor-pointer" onClick={handleLinkClick}>Directory</Link>
                   <Link to="/Inbox" className="hover:bg-gray-100 hover:rounded py-1 px-2 cursor-pointer" onClick={handleLinkClick}>Inbox</Link>
                    {isAdmin && (
                        <>
                        <Link to="/Admin" className="hover:bg-gray-100 hover:rounded py-1 px-2 cursor-pointer" onClick={handleLinkClick}>Manage Admin</Link>
                        <Link to="/ManageUsers" className="hover:bg-gray-100 hover:rounded py-1 px-2 cursor-pointer" onClick={handleLinkClick}>Manage Users</Link>
                        </>
                    )}
               </div>
           )}
       </div>
   );
}

export default Dropdownpls;
