import React from 'react';
import { PencilSquareIcon, PlusIcon, TrashIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { useAuth } from '../auth/AuthContext';

export default function Admin() {
    const { currentUser, isAdmin } = useAuth();
    const [email, setEmail] = useState("");
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isAdmin) {
            loadUsers();
        }
    }, [isAdmin]);

    const loadUsers = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'profiles'));
            const usersData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setUsers(usersData);
        } catch (error) {
            console.error('Error loading users: ', error);
        }
    };

    const handleAddAdmin = async () => {
        try {
            const userDocRef = doc(db, 'profiles', selectedUser);
            await updateDoc(userDocRef, { isAdmin: true });
            console.log('Admin added successfully');
            setSelectedUser("");
        } catch (error) {
            setError('Failed to add admin');
            console.error('Error adding admin: ', error);
        }
    };

    const handleRemoveAdmin = async () => {
        try {
            const userDocRef = doc(db, 'profiles', selectedUser);
            await updateDoc(userDocRef, { isAdmin: false });
            console.log('Admin removed successfully');
            setSelectedUser("");
        } catch (error) {
            setError('Failed to remove admin');
            console.error('Error removing admin: ', error);
        }
    };

    return (
        <div>
            <div className="flex flex-col justify-center mt-20 mb-24">
                {currentUser && isAdmin ? (
                    <>
                        <div className="flex justify-center py-5">
                            <div className="w-96 flex flex-col items-center justify-center">
                                <label
                                    htmlFor="user-select"
                                    className="block w-96 mb-2 text-lg font-medium text-gray-900 dark:text-white"
                                >
                                    Select User
                                </label>
                                <div className="relative mb-4 w-96">
                                    <select
                                        id="user-select"
                                        value={selectedUser}
                                        onChange={(e) => setSelectedUser(e.target.value)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    >
                                        <option value="">Select a user</option>
                                        {users.map(user => (
                                            <option key={user.id} value={user.id}>{user.name} ({user.email})</option>
                                        ))}
                                    </select>
                                </div>
                                {error && <p className="text-red-500">{error}</p>}
                            </div>
                        </div>
                        <div className='flex justify-center'>
                            <div className='grid grid-cols-2 w-1/2 object-center'>
                                <button
                                    onClick={handleAddAdmin}
                                    className="bg-gren rounded font-montserrat p-2 flex items-center justify-center hover:text-white m-5"
                                >
                                    <PlusIcon className="h-6 w-6 mr-2" />
                                    Add Admin
                                </button>
                                <button
                                    onClick={handleRemoveAdmin}
                                    className="bg-red-400 rounded font-montserrat p-2 flex items-center justify-center hover:text-white m-5"
                                >
                                    <TrashIcon className="h-6 w-6 mr-2" />
                                    Remove Admin
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>You are not an admin</div>
                )}
            </div>
        </div>
    );
}