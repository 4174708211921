import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db, auth } from '../firebase';
import { getDoc, doc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useAuth } from '../auth/AuthContext';
import pfp from '../pics/defaultpfp.png';
import { useNavigate } from 'react-router-dom';

const ProfileDisplay = () => {
    const { id } = useParams();
    const { currentUser } = useAuth();
    const [profile, setProfile] = useState(null);
    const [isCurrentUserProfile, setIsCurrentUserProfile] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const docRef = doc(db, 'profiles', id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setProfile(docSnap.data());
                } else {
                    console.error("No such document");
                }
            } catch (error) {
                console.error("Error fetching profile: ", error);
            }
        };
        fetchProfile();
    }, [id]);

    useEffect(() => {
        if (currentUser && currentUser.uid === id) {
            setIsCurrentUserProfile(true);
        } else {
            setIsCurrentUserProfile(false);
        }
    }, [currentUser, id]);

    if (!profile) {
        return <div>Loading...</div>;
    }

    return (
        <div className='grid grid-cols-3'>
            <div className='col-span-1 flex flex-col items-start ml-10 mt-10'>
                <h1 className='font-montserrat font-bold text-2xl mb-10'>{isCurrentUserProfile ? 'PROFILE' : `${profile.name.toUpperCase()}'S PROFILE`}</h1>
                <div className='flex flex-col items-center w-full justify-center'>
                    <img
                        src={profile.logoURL || pfp}
                        className='rounded-full object-cover object-center h-48 w-48 mb-2'
                        style={{ aspectRatio: '1 / 1' }}
                        alt=""
                    />
                    <h2 className='font-montserrat text-md mb-1'>{profile.email}</h2>
                    <div className='flex w-full justify-center'>
                        <input
                            className='w-1/2 pl-2 text-center rounded font-montserrat mb-1'
                            type="text"
                            id="number"
                            value={profile.number}
                            placeholder='Phone number'
                            readOnly={!isCurrentUserProfile}
                        />
                    </div>
                    <Link to='/Directory' className='font-montserrat text-xl text-org font-bold mt-20 hover:text-navy mb-2 '>Go to NExUS Directory</Link>
                    <Link to='/Organizations' className='font-montserrat text-xl text-org font-bold hover:text-navy mb-20 '>Go to NExUS Organizations</Link>

                    {isCurrentUserProfile && (
                        <button
                            className='text-black ml-2 px-1 rounded text-sm font-montserrat'
                            onClick={() => navigate('/Profile:')}
                        >
                        </button>
                    )}
                </div>
            </div>
            <div className='col-span-2 flex flex-col mt-20 ml-5'>
                <h1 className='font-montserrat font-bold text-lg'>Biography</h1>
                <textarea
                    className=' rounded mr-10 mt-2 h-12 font-montserrat overflow-scroll'
                    value={profile.biography}
                    readOnly={!isCurrentUserProfile}
                />
                <h1 className='font-montserrat font-bold text-lg mt-8'>Areas of Development</h1>
                <div className='flex flex-wrap mt-2'>
                    {profile.areasOfInterest.map(option => (
                        <div key={option} className='inline-flex items-center bg-blue-200 text-blue-800 rounded-full px-3 py-1 m-1'>
                            <span>{option}</span>
                        </div>
                    ))}
                </div>
                <h1 className='font-montserrat font-bold text-lg mt-5'>Areas of Expertise</h1>
                <textarea
                    className='rounded mr-10 mt-2 h-12 font-montserrat'
                    value={profile.expertise}
                    readOnly={!isCurrentUserProfile}
                />
            </div>
        </div>
    );
};

export default ProfileDisplay;
