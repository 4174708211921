//for auth-database communication
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; 

export const addUserProfile = async (userId, email) => {
    await setDoc(doc(db, 'profiles', userId), {
        email: email
    }, { merge: true }); 
};

export const loadProfile = async (uid) => {
    try {
        const docRef = doc(db, 'profiles', uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            console.error('No such document');
            return null;
        }
    } catch (error) {
        console.error('Error loading profile: ', error);
        return null;
    }
};
