import React from "react";
import About from "./components/About";
import Contact from "./components/Contact";
import Homepage from "./components/Homepage"
import Navbar from "./components/Navbar";
import Events from "./components/Events";
import Opportunities from "./components/Opportunities";
import Partners from "./components/Partners";
import Media from "./components/Media"
import Footer from "./components/Footer";
import Login from "./auth/Login";
import Profile from './components/Profile';
import AddSponsors from "./components/AddSponsors";
import AddPartners from "./components/AddPartners"
import AddOpportunities from "./components/AddOpportunities"
import EditOpportunities from "./components/EditOpportunities"
import Network from "./components/Network"
import Organizations from "./components/Organizations"
import DoDResources from "./components/DoDResources";
import EditPartners from "./components/EditPartners";
import EditSponsors from "./components/EditSponsors";
import Directory from "./components/Directory";
import Admin from "./components/Admin";
import AdminRoute from "./auth/AdminRoute";
import EditAbout from "./components/EditAbout";
import { AuthProvider } from "./auth/AuthContext";
import EditMedia from "./components/EditMedia";
import AddMedia from "./components/AddMedia";
import ProfileDisplay from "./components/ProfileDisplay";
import ManageUsers from "./components/ManageUsers";
import EditResources from "./components/EditResources";
import ChangePassword from "./components/ChangePassword";
import AddResources from "./components/AddResources";


// import Network from './Network';
// import Directory from './Directory';
// import DoDResources from './DoDResources';
// import Projects from './Projects';


import {
 Routes,
 Route,
 BrowserRouter
} from "react-router-dom";
import Resources from "./components/Resources";
import ProtectedRoute from './auth/ProtectedRoute';

export default function App() {
 return (
   <AuthProvider>
     <Navbar />
     <div>
       <Routes>
         <Route path="/" element={<Homepage />} />
         <Route path="/About" element={<About />} />
         <Route path="/Contact" element={<Contact />} />
         <Route path="/Events" element={<Events />} />
         <Route path="/Opportunities" element={<Opportunities />} />
         <Route path="/Partners" element={<Partners />} />
         <Route path="/Media" element={<Media />} />
         <Route path="/Resources" element={<Resources />} />
         <Route element={<ProtectedRoute />}>
           <Route path="/Profile" element={<Profile />} forceRefresh={true}/>
           <Route path="/AddSponsors" element={<AddSponsors />} />
           <Route path="/AddPartners" element={<AddPartners />} />
           <Route path="/AddOpportunities" element={<AddOpportunities />} />
           <Route path="/AddResources" element={<AddResources />} />
           <Route path="/EditOpportunities/:id" element={<EditOpportunities />} />
           <Route path="/Network" element={<Network />} />
           <Route path="/Organizations" element={<Organizations />} />
           <Route path="/Inbox" element={<DoDResources />} />
           <Route path="/EditSponsors/:id" element={<EditSponsors />} />
           <Route path="/EditPartners/:id" element={<EditPartners />} />
           <Route path="/Directory" element={<Directory />} />
           <Route path="/EditAbout" element={<EditAbout />} />
           <Route path="/EditMedia/:id" element={<EditMedia />} />
           <Route path="/AddMedia" element={<AddMedia />} />
           <Route path="/EditResources/:id" element={<EditResources />}/>
           <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route element={<AdminRoute />}>
                <Route path="/Admin" element={<Admin />} />
                <Route path="/EditAbout" element={<EditAbout />} />
                <Route path="/ManageUsers" element={<ManageUsers />} />
                <Route path="/Resources" element={<Resources />} />
            </Route>
           <Route path="/no-access" component={() => <div>No access</div>} />

           {/* 
           
           <Route path="/Projects" element={<Projects />} />
            */}
         </Route>
         <Route path="/Profile/:id" element={<ProfileDisplay />} /> {/*why do we have two profiles lol*/}
         <Route path="/Login" element={<Login />} />
       </Routes>
     </div>
     <Footer />
      </AuthProvider>
 );
}