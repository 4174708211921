import React, { useEffect, useState, useRef } from 'react';
import { db, auth, storage } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Authentication from '../auth/Authentication';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getDoc, doc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

const EditOpportunities = (props) => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        logo: null,
        title: "",
        oppLink: "",
        department: "",
        topic: "",
        type: "",
        startDate: "",
        endDate: "",
        endTime: "",
        flyer: null
    })

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [departmentData, setDepartmentData] = useState({});
    const fileInputRef = useRef(null);

    useEffect(() => {
        const fetchDepartmentData = async () => {
            const partnersCollection = collection(db, 'partners');
            const partnerSnapshot = await getDocs(partnersCollection);
            const partnersList = partnerSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            const data = partnersList.reduce((acc, partner) => {
                acc[partner.name] = {
                    logo: partner.logoURL,
                    url: partner.link
                };
                return acc;
            }, {});
            setDepartmentData(data);
        };

        const fetchOpportunity = async () => {
            try {
                const docRef = doc(db, 'opportunities', id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setFormData({
                        logoURL: departmentData[data.department]?.logo || departmentData['Other']?.logo,
                        flyerURL: data.flyerURL || "",
                        department: data.department || "",
                        title: data.title || "",
                        oppLink: data.oppLink || "",
                        topic: data.topic || "",
                        type: data.type || "",
                        startDate: data.startDate || "",
                        endDate: data.endDate || "",
                        endTime: data.endTime || "",
                        departmentURL: departmentData[data.department]?.url || departmentData['Other']?.url,
                    });
                } else {
                    console.error("No such document");
                }
            } catch (error) {
                console.error("Error getting document:", error);
            }
            setLoading(false);
        };

        fetchDepartmentData().then(fetchOpportunity);
    }, [id]);

    const departmentOptions = Object.keys(departmentData).map(key => ({
        label: key,
        value: key
    }));


    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            setFormData({ ...formData, [e.target.name]: e.target.files[0] });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
            ...(name === 'department' && {
                logoURL: value === 'Other' ? '' : (departmentData[value]?.logo || departmentData['Other']?.logo),
                departmentURL: value === 'Other' ? '' : (departmentData[value]?.url || departmentData['Other']?.url),
            }),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const docRef = doc(db, 'opportunities', id);

        // Initialize updatedData with formData
        const updatedData = {
            title: formData.title,
            oppLink: formData.oppLink,
            department: formData.department,
            topic: formData.topic,
            type: formData.type,
            startDate: formData.startDate,
            endDate: formData.endDate,
            endTime: formData.endTime
        };

        // Handle logo upload
        if (formData.logo && typeof formData.logo === 'object') {
            try {
                const logoRef = ref(storage, `opportunities/${uuidv4()}-${formData.logo.name}`);
                await uploadBytes(logoRef, formData.logo);
                const logoURL = await getDownloadURL(logoRef);
                updatedData.logoURL = logoURL;
            } catch (error) {
                console.error("Error uploading logo:", error);
            }
        } else if (formData.logoURL){
            updatedData.logoURL = formData.logoURL; 
        }

        // Handle flyer upload
        if (formData.flyer && typeof formData.flyer === 'object') {
            try {
                const flyerRef = ref(storage, `opportunities/${uuidv4()}-${formData.flyer.name}`);
                await uploadBytes(flyerRef, formData.flyer);
                const flyerURL = await getDownloadURL(flyerRef);
                updatedData.flyerURL = flyerURL;
            } catch (error) {
                console.error("Error uploading flyer:", error);
            }
        } else if (formData.flyerURL){
            updatedData.flyerURL = formData.flyerURL; 
        }

        // Remove undefined values from updatedData
        for (const key in updatedData) {
            if (updatedData[key] === undefined) {
                delete updatedData[key];
            }
        }

        try {
            await updateDoc(docRef, updatedData);
            fileInputRef.current.value = "";
            navigate('/Opportunities');
        } catch (error) {
            console.error("Error updating document:", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>
    }

    const Dropdown = ({ label, value, options, onChange }) => (
        <label className='block text-lg font-montserrat mb-2'>
            {label}
            <select value={value} onChange={onChange} className='w-full p-2 border rounded font-montserrat'>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </label>
    );

    return (

        <form className='flex flex-col items-center bg-gray-100 p-6 mt-10 rounded shadow-md' onSubmit={handleSubmit}>
            <h2 className='text-2xl font-montserrat font-bold mb-4'>Edit Opportunities</h2>
            <div className='w-full mb-4'>
                <Dropdown
                    label="Organization:"
                    options={[{ label: 'Select department', value: "" }, ...departmentOptions]}
                    value={formData.department}
                    onChange={(event) => handleInputChange({ target: { name: 'department', value: event.target.value } })}
                />
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="title" className='block text-lg font-montserrat mb-2'>Title:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="text"
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                />
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="oppLink" className='block text-lg font-montserrat mb-2'>Opportunity Link:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="text"
                    id="oppLink"
                    name="oppLink"
                    value={formData.oppLink}
                    onChange={handleInputChange}
                />
            </div>

            <div className='w-full mb-4'>
                <label htmlFor="topic" className='block text-lg font-montserrat mb-2'>Topic:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="text"
                    id="topic"
                    name="topic"
                    value={formData.topic}
                    onChange={handleInputChange}
                />
            </div>
            <div className='w-full mb-4'>
                <Dropdown
                    label="Opportunity Type:"
                    options={[
                        { label: 'Select type of opportunity', value: "" },
                        { label: 'Competition', value: 'Competition' },
                        { label: 'Program', value: 'Program' },
                        { label: 'Other', value: 'Other' },
                    ]}
                    value={formData.type}
                    onChange={(event) => handleInputChange({ target: { name: 'type', value: event.target.value } })}
                />
            </div>
            <div className="grid grid-cols-2 gap-16 justify-start">
                <div>
                    <label htmlFor="startDate" className="font-montserrat block text-gray-700 font-medium mb-2">
                        Start Date:
                    </label>
                    <input
                        type="date"
                        className="w-full p-2 mb-4 border border-gray-300 rounded"
                        id="startDate"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label htmlFor="endDate" className="font-montserrat block text-gray-700 font-medium mb-2">
                        End Date:
                    </label>
                    <input
                        type="date"
                        className="w-full p-2 mb-4 border border-gray-300 rounded"
                        id="endDate"
                        name="endDate"
                        value={formData.endDate}
                        onChange={handleInputChange}
                    />
                    <input
                        type="time"
                        className="w-full p-2 mb-4 border border-gray-300 rounded"
                        id="endTime"
                        name="endTime"
                        value={formData.endTime}
                        onChange={handleInputChange}
                    />
                </div>

            </div>
            <div className='w-full mb-4'>
                <label htmlFor="flyer" className='block text-lg font-montserrat mb-2'>Opportunity Flyer:</label>
                <input
                    className='w-full p-2 border rounded font-montserrat'
                    type="file"
                    id="flyer"
                    name="flyer"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
            </div>
            <div>
                <Link to="/opportunities">
                    <button
                        type="button"
                        className='bg-gray-500 text-white p-2 rounded font-montserrat hover:bg-org mr-5'
                    >
                        Back
                    </button>
                </Link>
                <button
                    type="submit"
                    className='bg-gren text-white p-2 rounded font-montserrat hover:bg-org'
                >
                    Update
                </button>
            </div>
        </form>
    );
}

export default EditOpportunities;