import React from 'react';
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from "react";
import { collection, getDocs } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import pfp from '../pics/defaultpfp.png';

const Modal = ({ emails, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded shadow-lg w-1/2 relative">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-4 text-2xl font-bold"
                >
                    &times;
                </button>
                <h2 className="text-2xl font-bold mb-4">Email List</h2>
                <div className="max-h-96 overflow-y-auto">
                    {emails.map((email, index) => (
                        <p key={index} className="font-montserrat text-lg mb-2">{email}</p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default function Directory() {
    const [authenticated, setAuthenticated] = useState(false);
    const [userId, setUserId] = useState(null);
    const [profiles, setProfiles] = useState({});
    const [partners, setPartners] = useState({});
    const [emails, setEmails] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserId(user.uid);
                loadProfiles();
                fetchPartners();
            } else {
                setUserId(null);
            }
        });
        return () => unsubscribe();
    }, []);

    const fetchPartners = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'partners'));
            const partnersData = querySnapshot.docs.reduce((acc, doc) => {
                acc[doc.data().name] = {
                    logo: doc.data().logoURL,
                    url: doc.data().link,
                };
                return acc;
            }, {});
            setPartners(partnersData);
        } catch (error) {
            console.error('Error fetching partners:', error);
        }
    };

    const sortedDepartments = [...Object.keys(partners)].sort((a, b) => {
        if (a === 'Other') return 1;
        if (b === 'Other') return -1;
        return a.localeCompare(b);
    });

    const loadProfiles = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'profiles'));
            const profilesData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            const groupedProfiles = profilesData.reduce((acc, profile) => {
                const department = profile.department || 'Other';
                if (!acc[department]) {
                    acc[department] = [];
                }
                acc[department].push(profile);
                return acc;
            }, {});

            setProfiles(groupedProfiles);

            // Extract emails
            const emailsList = profilesData.map(profile => profile.email);
            setEmails(emailsList);
        } catch (error) {
            console.error('Error loading profiles: ', error);
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("Auth state changed: ", user);
            setAuthenticated(!!user);
        });
        return () => unsubscribe();
    }, []);

    const handleProfileClick = (profileId) => {
        if (profileId === userId) {
            navigate('/Profile');
        } else {
            navigate(`/Profile/${profileId}`);
        }
    };

    return (
        <div>
            <section className="flex bg-grey w-full h-48">
                <p className="m-auto text-navy font-montserrat font-bold text-center text-5xl">
                    Directory
                </p>
            </section>
            <div className='justify-center flex mt-4'>
                <button
                    className="text-black font-montserrat bg-sky-200 text-center py-2 px-4 rounded"
                    onClick={() => setShowModal(true)}
                >
                    Email List
                </button>
                {showModal && <Modal emails={emails} onClose={() => setShowModal(false)} />}
            </div>

            <div className="flex justify-end mt-5 mr-5">
                {authenticated ? (
                    <>
                        <div className='flex flex-col w-full'>
                            {sortedDepartments.map(department => (
                                <div className='flex flex-col grid-cols-3' key={department}>
                                    <div className="inline-flex items-center justify-center w-full relative mt-6">
                                        <hr className="w-full h-1 my-5 bg-navy border-0 ml-5" />
                                        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2">
                                            <h1 className="text-navy font-montserrat font-bold text-center text-4xl">{department}</h1>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-3 gap-4 mt-5 text-center p-3'>
                                        {profiles[department]?.map(profile => (
                                            <div key={profile.id} className='bg-grey p-2 border m-3 rounded flex justify-start'>
                                                <div className='grid grid-cols-3 gap-2'>
                                                    <div className='col-span-1'>
                                                        <img src={profile.logoURL || pfp} className='rounded-full object-cover object-center h-32 w-32 ml-1 mt-3 mb-3'
                                                            style={{ aspectRatio: '1 / 1' }}
                                                            alt="" />
                                                    </div>
                                                    <div className='flex justify-center flex-col font-montserrat col-span-2 overflow-wrap mr-2'>
                                                        <div 
                                                            className='font-bold text-xl mb-1 cursor-pointer hover:text-org'
                                                            onClick={() => handleProfileClick(profile.id)}
                                                        >
                                                            {profile.name}
                                                        </div>
                                                        <h2 className='mb-2 text-lg'>{profile.role}</h2>
                                                        <h3 className='text-medium'>{profile.email}</h3>
                                                        <h3 className='text-medium'>{profile.number}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {!profiles[department] && (
                                            <p className='font-montserrat text-center'>No profiles found for this department.</p>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <div></div>
                )}
            </div>
        </div>
    );
}
