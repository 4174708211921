import React, { useState, useRef, useEffect, useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "tailwindcss/tailwind.css";
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { useNavigate } from 'react-router-dom';
import CustomAgenda from './CustomAgenda';
import "./events.css";
import { AiOutlineCaretUp, AiOutlineCaretDown } from "react-icons/ai";
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import ReactToPrint from "react-to-print";
import { PlusIcon, PencilSquareIcon, EyeIcon, EyeSlashIcon, TrashIcon, XCircleIcon, PrinterIcon } from "@heroicons/react/24/solid";


const localizer = momentLocalizer(moment);

const Dropdown = ({ label, value, options, onChange, id }) => (
    <label className='block mb-2'>
        <select value={value} onChange={onChange} className='placeholder-gray-500 bg-grey justify-center border rounded' id={id}>
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    </label>
);



const Events = () => {
    const fileInputRef = useRef(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showPartDropdown, setShowPartDropdown] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [eventTitle, setEventTitle] = useState("");
    const [eventLocation, setEventLocation] = useState("");
    const [eventStartDate, setEventStartDate] = useState("");
    const [eventStartTime, setEventStartTime] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [eventEndDate, setEventEndDate] = useState("");
    const [eventEndTime, setEventEndTime] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [eventPeople, setEventPeople] = useState("");
    const [eventType, setEventType] = useState("");
    const [selectEvent, setSelectEvent] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [partners, setPartners] = useState({});
    const [flyer, setFlyer] = useState(null);
    const navigate = useNavigate();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState(["All"]);
    const [eventPrivate, setEventPrivate] = useState(false); // New state for event privacy
    const [eventLink, setEventLink] = useState("");

    const EventModal = ({ isOpen, closeModal, event }) => {
        if (!isOpen) return null;

        return (
            <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg w-3/4">
                    <h2 className="text-2xl font-bold mb-4">{event.title}</h2>
                    <p className="text-lg mb-4">{event.description}</p>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg" onClick={closeModal}>
                        Close
                    </button>
                </div>
            </div>
        );
    };

    const openModal = (event) => {
        setSelectedEvent(event);
    };

    const closeModal = () => {
        setSelectedEvent(null);
    };


    useEffect(() => {
        const fetchEvents = async () => {
            const eventsCollection = collection(db, 'calendar');
            const eventsSnapshot = await getDocs(eventsCollection);
            const eventsList = eventsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                start: doc.data().start.toDate(),
                end: doc.data().end.toDate(),
            }));
            setEvents(eventsList);
        }
        fetchEvents();

        const fetchPartners = async () => {
            const querySnapshot = await getDocs(collection(db, 'partners'));
            const partnersData = querySnapshot.docs.reduce((acc, doc) => {
                acc[doc.data().name] = {
                    logo: doc.data().logoURL,
                    url: doc.data().link,
                };
                return acc;
            }, {});
            setPartners(partnersData);
        };
        fetchPartners();
    }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("Auth state changed: ", user);
            setAuthenticated(!!user);
        });
        return () => unsubscribe();
    }, []);

    const handleSelectSlot = (slotInfo) => {
        if (!authenticated) {
            alert("You must be logged in to create an event.");
            return;
        }
        setShowModal(true);
        setEventStartDate(moment(slotInfo.start).format("YYYY-MM-DD"));
        setEventStartTime(moment(slotInfo.start).format("HH:mm"));
        setEventEndDate(moment(slotInfo.end).format("YYYY-MM-DD"));
        setEventEndTime(moment(slotInfo.end).format("HH:mm"));
        setSelectEvent(null);
    };

    const handleSelectedEvent = (event) => {
        setShowModal(true);
        setSelectEvent(event);
        setEventTitle(event.title);
        setEventLocation(event.location);
        setEventStartDate(moment(event.start).format("YYYY-MM-DD"));
        setEventStartTime(moment(event.start).format("HH:mm"));
        setEventEndDate(moment(event.end).format("YYYY-MM-DD"));
        setEventEndTime(moment(event.end).format("HH:mm"));
        setEventDescription(event.description);
        setEventPeople(event.people);
        setDepartments(event.departments);
        setEventType(event.type);
        setSelectedOptions(event.selectedoptions);
        setFlyer(event.flyerURL ? { name: "View Flyer", url: event.flyerURL } : null);
        setEventPrivate(event.private || false);
        setEventLink(event.link);
    };

    const uploadFile = async (event) => {
        const selectedFile = event.target.files[0];
        setFlyer(selectedFile);
    };

    const saveEvent = async () => {
        if (!authenticated) {
            alert("You must be logged in to save an event.");
            return;
        }
        const startDateTime = moment(
            `${eventStartDate}T${eventStartTime}`
        ).toDate();
        const endDateTime = moment(`${eventEndDate}T${eventEndTime}`).toDate();
        let flyerURL = "";

        if (flyer && flyer.name !== "View Flyer") {
            const uniqueName = `${uuidv4()}-${flyer.name}`;
            const flyerRef = ref(storage, `calendar/${uniqueName}`);
            const snapshot = await uploadBytes(flyerRef, flyer);
            console.log("Upload successful: ", snapshot);

            flyerURL = await getDownloadURL(snapshot.ref);
            console.log("File available at: ", flyerURL);
        }

        if (eventTitle && startDateTime && endDateTime) {
            if (selectEvent) {
                const updatedEvent = {
                    ...selectEvent,
                    title: eventTitle,
                    description: eventDescription,
                    location: eventLocation,
                    start: startDateTime,
                    end: endDateTime,
                    people: eventPeople,
                    departments: departments,
                    type: eventType,
                    link: eventLink,
                    selectedoptions: selectedOptions,
                    flyerURL: flyerURL || selectEvent.flyerURL, // use existing URL if no new flyer uploaded
                    private: eventPrivate, // Include the privacy status
                };
                const eventDocRef = doc(db, "calendar", selectEvent.id);
                await updateDoc(eventDocRef, updatedEvent);
                const updatedEvents = events.map((event) =>
                    event.id === selectEvent.id ? updatedEvent : event
                );
                setEvents(updatedEvents);
            } else {
                const newEvent = {
                    title: eventTitle,
                    description: eventDescription,
                    start: startDateTime,
                    end: endDateTime,
                    location: eventLocation,
                    people: eventPeople,
                    departments: departments,
                    selectedoptions: selectedOptions,
                    link: eventLink,
                    type: eventType,
                    flyerURL: flyerURL,
                    private: eventPrivate, // Include the privacy status
                };
                const docRef = await addDoc(collection(db, "calendar"), newEvent);
                setEvents([...events, { id: docRef.id, ...newEvent }]);
            }
            setShowModal(false);
            resetForm();
        }
    };


    const deleteEvents = async () => {
        if (!authenticated) {
            alert("You must be logged in to delete an event.");
            return;
        }
        if (selectEvent) {
            const eventDocRef = doc(db, 'calendar', selectEvent.id);
            await deleteDoc(eventDocRef);
            const updatedEvents = events.filter((event) => event.id !== selectEvent.id);
            setEvents(updatedEvents);
            setShowModal(false);
            resetForm();
        }
    };

    const handleFilterClick = (type) => {
        if (type === "All") {
            setSelectedFilters(["All"]);
        } else {
            setSelectedFilters((prevFilters) => {
                if (prevFilters.includes("All")) {
                    return [type];
                } else if (prevFilters.includes(type)) {
                    return prevFilters.filter(filter => filter !== type);
                } else {
                    return [...prevFilters, type];
                }
            });
        }
    };

    const filteredEvents = events.filter(event => {
        if (event.private && !authenticated) {
            return false;
        }
        if (selectedFilters.includes('All') || selectedFilters.includes(event.type)) {
            return true;
        }
        return false;
    });

    // const filteredEvents = selectedFilters.includes("All")
    //     ? events.filter(event => !event.private || (event.private && authenticated))
    //     : events.filter(event => selectedFilters.includes(event.type) && (!event.private || (event.private && authenticated)));

    const isSelected = (type) => selectedFilters.includes(type);

    const togglePrivacy = () => {
        setEventPrivate(!eventPrivate);
    };

    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const handleOptionChange = (department) => {
        if (departments.includes(department)) {
            setDepartments(departments.filter(item => item !== department));
        } else {
            setDepartments([...departments, department]);
        }
    };

    const handleTagRemove = (department) => {
        setDepartments(departments.filter(item => item !== department));
    };

    const options = [
        { value: 'Government', label: 'Government' },
        { value: 'Industry', label: 'Industry' },
        { value: 'Academia', label: 'Academia' },
        { value: 'Community', label: 'Community' },
    ];

    const resetForm = () => {
        setEventTitle("");
        setEventLocation("");
        setEventStartDate("");
        setEventStartTime("");
        setEventEndDate("");
        setEventEndTime("");
        setEventDescription("");
        setSelectEvent(null);
        setEventPeople("");
        setDepartments([]);
        setSelectedOptions([]);
        setEventType("");
        setFlyer(null);
        setEventLink("");
        setEventPrivate(false);
    };

    const handleOptionPartChange = (option) => {

        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter(item => item !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    const handleDropdownPartToggle = () => {
        setShowPartDropdown(!showPartDropdown);
    };

    const handleTagPartRemove = (option) => {
        setSelectedOptions(selectedOptions.filter(item => item !== option));
    };

    const handlePrint = () => {
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        const sortedEvents = events.sort((a, b) => new Date(a.start) - new Date(b.start));

        const filteredEvents = (!start || !end) ? sortedEvents : sortedEvents.filter(event => {
            const eventStart = new Date(event.start);
            const eventEnd = new Date(event.end);
            return eventStart >= start && eventEnd <= end;
        });


        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.open();
        printWindow.document.write(`
            <html>
            <head>
                <title>Print Events</title>
                <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; }
                    th, td { border: 1px solid #ddd; padding: 8px; }
                    th { background-color: #f4f4f4; }
                    th:nth-child(1) { width: 20%; } /* Title */
                th:nth-child(2) { width: 30%; } /* Dates and Times */
                th:nth-child(3) { width: 20%; } /* Location */
                th:nth-child(4) { width: 30%; } /* Description */
                td:nth-child(1) { width: 20%; } /* Title */
                td:nth-child(2) { width: 30%; } /* Dates and Times */
                td:nth-child(3) { width: 20%; } /* Location */
                td:nth-child(4) { width: 30%; } /* Description */
                </style>
            </head>
            <body>
                <h1>Event List</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Dates and Times</th>
                            <th>Location</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${filteredEvents.map(event => `
                            <tr>
                                <td>${event.title}</td>
                                <td>${event.start} - ${event.end}</td>
                                <td>${event.location}</td>
                                <td>${event.description} <br><br> ${event.link} <br><br> ${event.people}</td>
                            </tr>
                        `).join('')}
                    </tbody>
                </table>
            </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
    };


    return (
        <>
            {authenticated && (
                <div className="ml-16 flex mb-2">
                    <button
                        onClick={handlePrint}
                    >
                        <PrinterIcon className="w-8 h-8 text-gray-800" />
                    </button>
                    <input
                        type="date"
                        className="border border-gray-300 rounded ml-3"
                        id="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                        type="date"
                        className="border border-gray-300 rounded ml-2"
                        id="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>

            )}
            <div className="grid grid-cols-7 mx-16">
                <div className={`m-2 rounded text-center cursor-pointer ${isSelected("All") ? 'border border-w-8 border-blue-500' : 'border'}`} onClick={() => handleFilterClick("All")}>All</div>
                <div className={`bg-industry m-2 rounded text-center cursor-pointer ${isSelected("Workshop") ? 'border border-w-8 border-blue-500' : ''}`} onClick={() => handleFilterClick("Workshop")}>Workshop</div>
                <div className={`bg-funding m-2 rounded text-center cursor-pointer ${isSelected("Conference") ? 'border border-w-8 border-blue-500' : ''}`} onClick={() => handleFilterClick("Conference")}>Conference</div>
                <div className={`bg-startups m-2 rounded text-center cursor-pointer ${isSelected("Experimentation") ? 'border border-w-8 border-blue-500' : ''}`} onClick={() => handleFilterClick("Experimentation")}>Experimentation</div>
                <div className={`bg-opportunities m-2 rounded text-center cursor-pointer ${isSelected("Opportunities") ? 'border border-w-8 border-blue-500' : ''}`} onClick={() => handleFilterClick("Opportunities")}>Opportunities</div>
                <div className={`bg-academia m-2 rounded text-center cursor-pointer ${isSelected("Outreach") ? 'border border-w-8 border-blue-500' : ''}`} onClick={() => handleFilterClick("Outreach")}>Outreach</div>
                <div className={`bg-other m-2 rounded text-center cursor-pointer ${isSelected("Other") ? 'border border-w-8 border-blue-500' : ''}`} onClick={() => handleFilterClick("Other")}>Other</div>
            </div>
            <div className="h-screen">
                <Calendar
                    localizer={localizer}
                    events={filteredEvents}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ margin: "50px" }}
                    selectable={authenticated}
                    onEventDrop={events}
                    onEventResize={events}
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectedEvent}
                    components={{
                        agenda: {
                            event: CustomAgenda // Use the custom agenda component
                        }
                    }}
                    eventPropGetter={(event, start, end, isSelected) => {
                        let eventStyle = {
                            backgroundColor: '#D9D9D9',
                            borderRadius: '5px',
                            opacity: 0.8,
                            color: 'black',
                            border: 'none'
                        };
                        if (event.type === 'Workshop') {
                            eventStyle.backgroundColor = '#B2CEFE';
                        } else if (event.type === 'Experimentation') {
                            eventStyle.backgroundColor = '#F8F3B0';
                        } else if (event.type === 'Opportunities') {
                            eventStyle.backgroundColor = '#F6CEDE';
                        } else if (event.type === 'Outreach') {
                            eventStyle.backgroundColor = '#F9DED7';
                        } else if (event.type === 'Conference') {
                            eventStyle.backgroundColor = '#BDE4DC';
                        } else if (event.type === 'Other') {
                            eventStyle.backgroundColor = '#E2BEF2';
                        }

                        return {
                            style: eventStyle
                        };
                    }}
                />

                {showModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white rounded-lg shadow-lg max-w-lg w-full h-screen overflow-scroll">
                            <div className="border-b p-4 flex justify-between items-center">
                                <h5 className="text-xl font-semibold">
                                    {selectEvent ? "Event Details" : "Add Event"}
                                </h5>
                                <button
                                    type="button"
                                    className="text-gray-500 hover:text-gray-700"
                                    onClick={() => {
                                        setShowModal(false);
                                        resetForm();
                                    }}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="p-4 overflow-scroll">
                                <label htmlFor="eventTitle" className="block text-gray-700 font-medium">
                                    Event Title:
                                </label>
                                {authenticated ? (
                                    <input
                                        type="text"
                                        className="w-full p-1 mb-3 border border-gray-300 rounded mt-2"
                                        id="eventTitle"
                                        value={eventTitle}
                                        onChange={(e) => setEventTitle(e.target.value)}
                                    />
                                ) : (
                                    <p className="mb-2">{eventTitle}</p>
                                )}
                                <label htmlFor="eventLocation" className="block text-gray-700 font-medium">
                                    Event Location:
                                </label>
                                {authenticated ? (
                                    <input
                                        type="text"
                                        className="w-full p-1 mb-3 border border-gray-300 rounded mt-2"
                                        id="eventLocation"
                                        value={eventLocation}
                                        onChange={(e) => setEventLocation(e.target.value)}
                                    />
                                ) : (
                                    <p className="mb-2">{eventLocation}</p>
                                )}
                                <label htmlFor="eventLink" className="block text-gray-700 font-medium">
                                    Event Link:
                                </label>
                                {authenticated ? (
                                    <input
                                        type="url"
                                        className="w-full p-1 mb-3 border border-gray-300 rounded mt-2"
                                        id="eventLink"
                                        value={eventLink}
                                        onChange={(e) => setEventLink(e.target.value)}
                                    />
                                ) : (
                                    <a href={eventLink} target="_blank" className="text-blue-500 hover:underline">{eventLink}</a>
                                )}
                                <div className="grid grid-cols-2 gap-4 mt-2">
                                    <div>
                                        <label htmlFor="eventStartDate" className="block text-gray-700 font-medium">
                                            Event Start Date:
                                        </label>
                                        {authenticated ? (
                                            <input
                                                type="date"
                                                className="w-full p-1 mb-2 border border-gray-300 rounded mt-2"
                                                id="eventStartDate"
                                                value={eventStartDate}
                                                onChange={(e) => setEventStartDate(e.target.value)}
                                            />
                                        ) : (
                                            <p className="mb-2">{eventStartDate}</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="eventEndDate" className="block text-gray-700 font-medium">
                                            Event End Date:
                                        </label>
                                        {authenticated ? (
                                            <input
                                                type="date"
                                                className="w-full p-1 mb-2 border border-gray-300 rounded mt-2"
                                                id="eventEndDate"
                                                value={eventEndDate}
                                                onChange={(e) => setEventEndDate(e.target.value)}
                                            />
                                        ) : (
                                            <p className="mb-2">{eventEndDate}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label htmlFor="eventStartTime" className="block text-gray-700 font-medium">
                                            Event Start Time:
                                        </label>
                                        {authenticated ? (
                                            <input
                                                type="time"
                                                className="w-full p-1 mb-2 border border-gray-300 rounded mt-2"
                                                id="eventStartTime"
                                                value={eventStartTime}
                                                onChange={(e) => setEventStartTime(e.target.value)}
                                            />
                                        ) : (
                                            <p className="mb-2">{eventStartTime}</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="eventEndTime" className="block text-gray-700 font-medium">
                                            Event End Time:
                                        </label>
                                        {authenticated ? (
                                            <input
                                                type="time"
                                                className="w-full p-1 mb-2 border border-gray-300 rounded mt-2"
                                                id="eventEndTime"
                                                value={eventEndTime}
                                                onChange={(e) => setEventEndTime(e.target.value)}
                                            />
                                        ) : (
                                            <p className="mb-2">{eventEndTime}</p>
                                        )}
                                    </div>
                                </div>
                                <label htmlFor="eventDescription" className="block text-gray-700 font-medium">
                                    Event Description:
                                </label>
                                {authenticated ? (
                                    <textarea
                                        className="w-full p-1 mb-2 border border-gray-300 rounded mt-2"
                                        id="eventDescription"
                                        value={eventDescription}
                                        onChange={(e) => setEventDescription(e.target.value)}
                                    />
                                ) : (
                                    <div className="col-span-3 text-start text-m font-regular mb-2">
                                        Description: {eventDescription.length > 500 ? `${eventDescription.substring(0, 500)}...` : eventDescription}
                                        {eventDescription.length > 100 && (
                                            <button onClick={() => openModal(selectEvent)} className="text-blue-500 hover:underline ml-1">Read more</button>
                                        )}
                                    </div>)}<>
                                    <div className="">
                                        <div className="">
                                            <label htmlFor="departments" className="block text-gray-700 font-medium">
                                                Affiliated With:
                                            </label>
                                            {authenticated ? (
                                                <div className="relative">
                                                    <div
                                                        className="border border-gray-300 rounded mt-2 p-1 cursor-pointer mb-2 "
                                                        onClick={() => setShowDropdown(!showDropdown)}
                                                    >
                                                        Select organizations
                                                        {showDropdown ? (
                                                            <AiOutlineCaretUp className="inline ml-2" />
                                                        ) : (
                                                            <AiOutlineCaretDown className="inline ml-2" />
                                                        )}
                                                    </div>
                                                    {showDropdown && (
                                                        <div className="absolute border border-gray-300 bg-white w-full mt-1 rounded shadow-lg z-10 h-24 overflow-scroll">
                                                            {Object.keys(partners).map((partner) => (
                                                                <label
                                                                    key={partner}
                                                                    className="block px-4 py-2 cursor-pointer hover:bg-gray-200"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        className="mr-2"
                                                                        checked={departments.includes(partner)}
                                                                        onChange={() => handleOptionChange(partner)}
                                                                    />
                                                                    {partner}
                                                                </label>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>

                                            ) : (
                                                <p className="mb-2">
                                                    {departments.length > 0 ? departments.join(", ") : "No departments selected"}
                                                </p>
                                            )}
                                            {/* Display Selected Tags */}{
                                                authenticated && (
                                                    <div className='mt-4 flex flex-wrap mb-2'>
                                                        {departments.filter(department => department.trim() !== '').map(department => (
                                                            <div key={department} className='inline-flex items-center bg-blue-200 text-blue-800 rounded-full px-3 py-1 m-1'>
                                                                {department}
                                                                <XCircleIcon
                                                                    onClick={() => handleTagRemove(department)}
                                                                    className='ml-2 h-5 w-5 cursor-pointer'
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="">
                                            <label htmlFor="eventPeople" className="block text-gray-700 font-medium">
                                                Point of Contact:
                                            </label>
                                            {authenticated ? (
                                                <input
                                                    type="text"
                                                    className="w-full mb-3 border border-gray-300 rounded px-1"
                                                    id="eventPeople"
                                                    value={eventPeople}
                                                    onChange={(e) => setEventPeople(e.target.value)}
                                                />
                                            ) : (
                                                <p className="mb-2">{eventPeople}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="">
                                            <label htmlFor="selectedoptions" className="block text-gray-700 font-medium">
                                                Participants:
                                            </label>
                                            {authenticated ? (
                                                <div className="relative">
                                                    <div
                                                        className="border border-gray-300 rounded mt-2 p-1 cursor-pointer mb-2 "
                                                        onClick={() => setShowPartDropdown(!showPartDropdown)}
                                                    >
                                                        Select groups
                                                        {showPartDropdown ? (
                                                            <AiOutlineCaretUp className="inline ml-2" />
                                                        ) : (
                                                            <AiOutlineCaretDown className="inline ml-2" />
                                                        )}
                                                    </div>
                                                    {showPartDropdown &&
                                                        <div className='overflow-scroll h-36 absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded shadow-lg font-montserrat'>
                                                            {options.map(option => (
                                                                <label key={option.value} className='flex items-center p-2 hover:bg-gray-100 cursor-pointer'>
                                                                    <input
                                                                        type='checkbox'
                                                                        className='form-checkbox'
                                                                        checked={selectedOptions.includes(option.value)}
                                                                        onChange={() => handleOptionPartChange(option.value)}
                                                                    />
                                                                    <span className='ml-2'>{option.label}</span>

                                                                </label>
                                                            ))}
                                                        </div>
                                                    }
                                                </div>

                                            ) : (
                                                <p className="mb-2">
                                                    {selectedOptions.length > 0 ? selectedOptions.join(", ") : "No participants selected"}
                                                </p>
                                            )}
                                            {/* Display Selected Tags */}{
                                                authenticated && (
                                                    <div className='mt-4 flex flex-wrap mb-2'>
                                                        {selectedOptions.filter(option => option.trim() !== '').map(option => (
                                                            <div key={option} className='inline-flex items-center bg-blue-200 text-blue-800 rounded-full px-3 py-1 m-1'>
                                                                {option}
                                                                <XCircleIcon
                                                                    onClick={() => handleTagRemove(option)}
                                                                    className='ml-2 h-5 w-5 cursor-pointer'
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                </>
                                <label htmlFor="eventType" className="block text-gray-700 font-medium">
                                    Event Type:
                                </label>
                                {authenticated ? (
                                    <select
                                        className="w-full p-1 border rounded mt-2 mb-2"
                                        value={eventType}
                                        onChange={(e) => setEventType(e.target.value)}
                                    >
                                        <option value="">Select Event Type</option>
                                        <option value="Workshop">Workshop</option>
                                        <option value="Experimentation">Experimentation</option>
                                        <option value="Opportunities">Opportunities</option>
                                        <option value="Outreach">Outreach</option>
                                        <option value="Conference">Conference</option>
                                        <option value="Other">Other</option>
                                    </select>
                                ) : (
                                    <p className="mb-2">{eventType}</p>
                                )}

                                <label htmlFor="eventFlyer" className="block text-gray-700 font-medium">
                                    Event Flyer:
                                </label>
                                <div className="flex">
                                    {authenticated ? (
                                        <>
                                            <input
                                                ref={fileInputRef}
                                                type="file"
                                                accept="image/*, application/pdf"
                                                onChange={uploadFile}
                                                className="hidden"
                                                id="eventFlyer"
                                            />
                                            <label
                                                htmlFor="eventFlyer"
                                                className="cursor-pointer bg-grey hover:bg-gray-400 text-black px-1 rounded mt-2"
                                            >
                                                Upload Flyer
                                            </label>
                                            {flyer && (
                                                <a
                                                    href={flyer.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-500 hover:underline mt-2 ml-2"
                                                >
                                                    View Flyer
                                                </a>
                                            )

                                            }
                                        </>
                                    ) : (
                                        flyer && (
                                            <a
                                                href={flyer.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-500 hover:underline"
                                            >
                                                View Flyer
                                            </a>
                                        )
                                    )}
                                </div>
                            </div>
                            {authenticated && (
                                <div className="flex justify-end p-4 border-t relative">
                                    {/* {selectEvent && (
                                        <div>
                                        <button onClick={togglePrivacy}>
                                            {eventPrivate ?  <EyeSlashIcon className="h-6 w-6 ml-30 mr-4" /> : <EyeIcon className="h-6 w-6 ml-30 mr-4" />}
                                        </button>
                                    </div>
                                    )} */}
                                    {selectEvent && (
                                        <button
                                            type="button"
                                            onClick={deleteEvents}
                                            className="absolute bottom left-3 bg-red-500 hover:bg-red-600 text-white py-1 px-4 rounded mr-2"
                                        >
                                            Delete
                                        </button>
                                    )}
                                    <div>
                                        <button onClick={togglePrivacy}>
                                            {eventPrivate ? <EyeSlashIcon className="h-6 w-6 ml-30 mr-4 mt-1" /> : <EyeIcon className="h-6 w-6 ml-30 mr-4 mt-1" />}
                                        </button>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={saveEvent}
                                        className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-4 rounded"
                                    >
                                        {selectEvent ? "Update Event" : "Add Event"}
                                    </button>

                                </div>
                            )}
                        </div>
                        <EventModal isOpen={selectedEvent !== null} closeModal={closeModal} event={selectedEvent} />
                    </div>
                )}
            </div>
        </>
    );
};


export default Events;

