import React from 'react';
import home from "../pics/home.png"
import { Link } from 'react-router-dom';

export default function Network() {
   return (
       <div>
           ur mom
       </div>
   );
}
