import React, { useRef, useEffect } from 'react';
import { db, auth, storage } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Authentication from '../auth/Authentication';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';

const EditPartners = (props) => {

   const { id } = useParams();
   const [logo, setLogo] = useState(null);
   const [link, setLink] = useState('');
   const [name, setName] = useState('');
   const [description, setDescription] = useState('');
   const [existingLogoURL, setExistingLogoURL] = useState('');
   const fileInputRef = useRef(null);

   const navigate = useNavigate();

   useEffect(() => {
       const fetchPartnerData = async() => {
           const docRef = doc(db, 'partners', id);
           const docSnap = await getDoc(docRef);
           if(docSnap.exists()) {
               const data = docSnap.data();
               setName(data.name);
               setLink(data.link);
               setDescription(data.description);
               setExistingLogoURL(data.logoURL);
           } else {
               console.error("No such document");
           }
       }
       fetchPartnerData();
   }, [id]);

   const uploadFile = async(file) => {
       let logoURL = existingLogoURL;

       if(logo) {
           const uniqueName = `${uuidv4()}-${logo.name}`;
           const logoRef = ref(storage, `partners/${uniqueName}`);
           await uploadBytes(logoRef, logo);
           logoURL = await getDownloadURL(logoRef);
       }

       const updatedData = {
           logoURL,
           link,
           description
       }

       const docRef = doc(db, 'partners', id);
       await updateDoc(docRef, updatedData);
       console.log('Document updated with id: ', id);

       fileInputRef.current.value = "";
       setLogo(null);
       navigate('/Partners');

   }


   return (
       <form className='flex flex-col items-center bg-gray-100 p-6 mt-10 rounded shadow-md'>
           <h2 className='text-2xl font-montserrat font-bold mb-4'>Add Partners</h2>
           <div className='w-full mb-4'>
               <label htmlFor="logo" className='block text-lg font-montserrat mb-2'>Sponsor logo:</label>
               <input
                   className='w-full p-2 border rounded font-montserrat'
                   type="file"
                   id="logo"
                   name="logo"
                   ref={fileInputRef}
                   onChange={(event) => {
                       setLogo(event.target.files[0]);
                   }}
               />
               {existingLogoURL && (
                   <img src={existingLogoURL} alt="partner logo" className="mt-4 h-32" />
               )}
           </div>
           <div className='w-full mb-4'>
               <label htmlFor="link" className='block text-lg font-montserrat mb-2'>Link:</label>
               <input
                   className='w-full p-2 border rounded font-montserrat'
                   type="url"
                   id="link"
                   value={link}
                   onChange={(event) => setLink(event.target.value)}
               />
           </div>
           <div className='w-full mb-4'>
               <label htmlFor="name" className='block text-lg font-montserrat mb-2'>Organization Acronym:</label>
               <input
                   className='w-full p-2 border rounded font-montserrat'
                   type="text"
                   id="name"
                   value={name}
                   onChange={(event) => setName(event.target.value)}
               />
           </div>
           <div className='w-full mb-4'>
               <label htmlFor="description" className='block text-lg font-montserrat mb-2'>Brief Description:</label>
               <textarea
                   className='w-full p-2 border rounded font-montserrat h-48'
                   type="text"
                   id="description"
                   value={description}
                   onChange={(event) => setDescription(event.target.value)}
               />
           </div>
           <button onClick={(e) => {
               e.preventDefault();
               uploadFile();
           }}
               type="submit"
               className='bg-gren text-white p-2 rounded font-montserrat hover:bg-org'
           >
               Update
           </button>
       </form>
   );
}

export default EditPartners;